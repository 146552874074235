import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  alpha,
} from "@mui/material";
import PropTypes from "prop-types";
import mailcon from "../../images/mailcon.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ADD_CONFIG_MAIL_BOX_URL,
  GET_CONFIG_MAIL_BOX_URL,
} from "../common/ApiUrl";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import CustomLoader from "../common/CustomLoader";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import Lock from "../../images/lock.svg";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  PickersDay,
} from "@mui/x-date-pickers";
import moment from "moment";
import { daysInWeek, format } from "date-fns";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInputSel = styled(Select)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    //   borderRadius: "32.5px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: "1px solid #00A1F1",
  color: "#202020",
  borderRadius: "8px !important",
  width: "35px",
  height: "35px",
  textTransform: "capitalize",
  fontSize: "12px",
  fontWeight: "400",
  marginRight: "10px !important",

  "&.MuiToggleButtonGroup-grouped:not(:last-of-type)": {
    borderRadius: "8px !important",
  },
  "&.MuiToggleButtonGroup-grouped:not(:first-of-type)": {
    borderRadius: "8px !important",
    borderLeft: "1px solid #489e92 !important",
  },
  "&:hover": {
    backgroundColor: "#00A1F1",
    color: "white",
  },
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: "#00A1F1",
    color: "white",
  },
}));
const initialValues = {
  dayCount: "",
  notificationEmail: "",
  happens: "No Schedule",
  dayOfWeek: "",
  whour: "",
  wminute: "",
  dhour: "",
  dminute: "",
  dateOfMonth: "",
  mhour: "",
  mminute: "",
};
const mailinput = [
  { name: "30 days", value: 30 },
  { name: "60 days", value: 60 },
  { name: "90 days", value: 90 },
  { name: "120 days", value: 120 },
];
const hapinput = [
  { name: "No Schedule", value: "No Schedule" },
  { name: "Weekly", value: "weekly" },
  { name: "Monthly", value: "monthly" },
  { name: "Daily", value: "daily" },
];
const weekInput = [
  { label: "Sun", order: 7, value: "Sunday" },
  { label: "Mon", order: 1, value: "Monday" },
  { label: "Tue", order: 2, value: "Tuesday" },
  { label: "Wed", order: 3, value: "Wednesday" },
  { label: "Thu", order: 4, value: "Thursday" },
  { label: "Fri", order: 5, value: "Friday" },
  { label: "Sat", order: 6, value: "Saturday" },
];
export default function ConfigureMailbox(props) {
  const { open, handleclose, successCallBack } = props;
  const [loading, setLoading] = useState(false);
  // const [menuOpen, setMenuOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const [happens, setHappens] = useState('No Schedule');
  const [daySelect, daySetSelect] = useState("Pick a day");
  const [dateSelect, SetDateSelect] = useState("Pick a date");
  const [showTimeOption, setShowTimeOption] = useState(false);
  const inputRef = useRef(null);

  const validationSchema = Yup.object({
    dayCount: Yup.string().required("Inactive duration is required"),
    notificationEmail: Yup.string().required("Notification email is required"),
  });
  const addConfiguration = async (values) => {
    const {
      happens,
      dhour,
      dminute,
      dayOfWeek,
      whour,
      wminute,
      dateOfMonth,
      mhour,
      mminute,
    } = values;
    console.log("values", values);
    let happenDayarr = "";
    let happenDay = "";
    let formattedTime = "";
    if (dayOfWeek != "") {
      console.log(
        "dayofweek",
        dayOfWeek,
        weekInput.filter((r) => r.value == dayOfWeek)
      );
      happenDayarr = weekInput.filter((r) => r.value == dayOfWeek);
      if (happenDayarr) {
        happenDay = happenDayarr[0].order;
      }
    }
    if (happens == "daily") {
      const date = new Date(0, 0, 0, dhour, dminute);

      // Using date-fns library for formatting
      formattedTime = format(date, "HH:mm");
    }
    if (happens == "weekly") {
      const date = new Date(0, 0, 0, whour, wminute);

      // Using date-fns library for formatting
      formattedTime = format(date, "HH:mm");
    }
    if (happens == "monthly") {
      const date = new Date(0, 0, 0, mhour, mminute);

      // Using date-fns library for formatting
      formattedTime = format(date, "HH:mm");
    }
    console.log("values", values, formattedTime, happens);

    if (happens != "" || happens != "No Schedule") {
      console.log("happens");
      if (happens == "daily" && formattedTime == "00:00") {
        CustomMessage(
          "Missing required parameters. Please provide all necessary information.",
          "error",
          enqueueSnackbar
        );
      } else if (
        happens == "weekly" &&
        (formattedTime == "00:00" || dayOfWeek == "")
      ) {
        console.log("happens", formattedTime, dayOfWeek);

        CustomMessage(
          "Missing required parameters. Please provide all necessary information.",
          "error",
          enqueueSnackbar
        );
      } else if (
        happens == "monthly" &&
        (formattedTime == "00:00" || dateOfMonth == "")
      ) {
        console.log("happens", formattedTime, dateOfMonth);

        CustomMessage(
          "Missing required parameters. Please provide all necessary information.",
          "error",
          enqueueSnackbar
        );
      } else {
        setLoading(true);
        const config = {
          method: "POST",
          url: ADD_CONFIG_MAIL_BOX_URL,
          headers: authHeader(),
          data: {
            dayCount: values.dayCount,
            notificationEmail: values.notificationEmail,
            cronType: happens,
            happenDay:
              happenDay && happens != "No Schedule"
                ? happenDay.toString()
                : null,
            happenTime:
              formattedTime && happens != "No Schedule" ? formattedTime : null,
            happenDate:
              dateOfMonth && happens != "No Schedule"
                ? dateOfMonth.toString()
                : null,
          },
        };
        try {
          const response = await API(config);
          const { status, data } = response.data;
          if (status == "Success") {
            CustomMessage(
              "Configured successfully",
              "success",
              enqueueSnackbar
            );
            successCallBack();
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      addConfiguration(values);
    },
  });
  const getConfiguration = async () => {
    const config = {
      method: "GET",
      url: GET_CONFIG_MAIL_BOX_URL,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        const {
          dayCount,
          notificationEmail,
          cronType,
          happenTime,
          happenDay,
          happenDate,
        } = data;
        let hr = null;
        let min = null;
        let hd = "";
        if (happenTime != null) {
          hr = happenTime.split(":")[0];
          min = happenTime.split(":")[1];
        }
        if (happenDay) {
          let fil = weekInput.filter((r) => r.order == happenDay);
          if (fil) {
            hd = fil[0].value;
          }
        }
        setValues({
          ...values,
          notificationEmail: notificationEmail,
          happens: cronType,
          dhour: cronType == "daily" ? hr : null,
          dminute: cronType == "daily" ? min : null,
          whour: cronType == "weekly" ? hr : null,
          wminute: cronType == "weekly" ? min : null,
          mhour: cronType == "monthly" ? hr : null,
          mminute: cronType == "monthly" ? min : null,
          dayOfWeek: hd,
          dateOfMonth: happenDate ? happenDate : "",
          dayCount: dayCount,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getConfiguration();
  }, []);
  const resetValues = () => {
    setValues(initialValues);
  };
  const handleChangeFun = (event, newDay) => {
    // Assuming your state is updated like this
    console.log("log", event, newDay);
    setValues((prevValues) => ({
      ...prevValues,
      dayOfWeek: newDay,
    }));
    daySetSelect(newDay);
  };
  const handleChangeFunDate = (event, newDay) => {
    console.log("log", event, newDay);
    setValues((prevValues) => ({
      ...prevValues,
      dateOfMonth: newDay,
    }));
    SetDateSelect(newDay);
  };
  const handleDay = (value) => {
    setValues((prevValues) => ({
      ...prevValues,
      dateOfMonth: value,
    }));
  };
  return (
    <>
      <div>
        <BootstrapDialog
          className="rq-user-popup org-popup "
          onClose={handleclose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleclose}
          ></BootstrapDialogTitle>
          <DialogContent>
            <Box style={{ textAlign: "center" }}>
              <img src={mailcon} />
            </Box>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              Configure Mailbox
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                xs={12}
                sx={{ marginTop: "25px" }}
                alignItems={"center !important"}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="my-input" className="conf-label">
                    Inactive Duration
                  </InputLabel>
                </Grid>
                <Grid xs={12} md={9}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <BootstrapInputSel
                      // disabled
                      labelId="dayCount"
                      id="dayCount"
                      value={values.dayCount}
                      label="dayCount"
                      name="dayCount"
                      onChange={handleChange}
                      // placeholder="User name (you can select more than 1)"
                      //   data-fname="domain"
                    >
                      {mailinput.map((rec, i) => {
                        return (
                          <MenuItem key={i} name={rec.name} value={rec.value}>
                            {rec.name}
                          </MenuItem>
                        );
                      })}
                    </BootstrapInputSel>
                    <FormHelperText error={true}>
                      {errors.dayCount && touched.dayCount
                        ? errors.dayCount
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                sx={{ marginTop: "25px" }}
                alignItems={"center !important"}
              >
                <Grid item xs={12} md={3}>
                  <InputLabel htmlFor="my-input" className="conf-label">
                    Happens
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={9}>
                  <FormControl
                    sx={{ width: "100%", mt: "5px" }}
                    variant="standard"
                  >
                    <BootstrapInputSel
                      labelId="happens"
                      id="happens"
                      value={values.happens}
                      label="happens"
                      name="happens"
                      onChange={handleChange}
                      // placeholder="User name (you can select more than 1)"
                      //   data-fname="domain"
                    >
                      {hapinput.map((rec, i) => {
                        return (
                          <MenuItem key={i} name={rec.name} value={rec.value}>
                            {rec.name}
                          </MenuItem>
                        );
                      })}
                    </BootstrapInputSel>
                    <FormHelperText error={true}>
                      {errors.happens && touched.happens ? errors.happens : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              {values.happens === "weekly" && (
                <>
                  <Grid
                    container
                    xs={12}
                    sx={{ marginTop: "25px" }}
                    alignItems={"baseline"}
                  >
                    <Grid item xs={12} md={3}>
                      <InputLabel htmlFor="my-input" className="conf-label">
                        Every
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      {/* <FormControl sx={{ width: "100%" }} variant="standard">
  {console.log('dw',daySelect)}
  <BootstrapInputSel
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  value={daySelect}
                  label="License"
                  placeholder="License"
                  inputRef={inputRef}
                  input={<OutlinedInput label="day" placeholder="Pick a day" />}
                  renderValue={(selected) =>selected}
                >
                 */}

                      <ToggleButtonGroup
                        value={values.dayOfWeek}
                        exclusive
                        aria-label="Date"
                        className="week_card"
                        onChange={handleChangeFun}
                      >
                        {weekInput.map((ele) => (
                          // <MenuItem key={ele.order} value={ele.value}>
                          <StyledToggleButton
                            sx={{ marginRight: "8px !important" }}
                            value={ele.value}
                            onClick={handleChangeFun}
                            name="dayOfWeek"
                            id="dayOfWeek"
                          >
                            {values.dayOfWeek === ele.value && (
                              <img src={Lock} className="lockIcon" />
                            )}
                            {ele.label}
                          </StyledToggleButton>
                        ))}
                      </ToggleButtonGroup>
                      {/* </BootstrapInputSel>
</FormControl> */}
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    sx={{ marginTop: "25px" }}
                    alignItems={"center !important"}
                  >
                    <Grid item xs={12} md={3}>
                      <InputLabel
                        htmlFor="my-input"
                        className="conf-label"
                        sx={{
                          textAlign: "left",
                          fontWeight: "700",
                          fontSize: "16px",
                          mt: "10px",
                        }}
                      >
                        On (EST)
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Box className="on-box-sty">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopTimePicker
                            ampm={false}
                            disableOpenPicker
                            open={showTimeOption}
                            value={moment({
                              hour: values.whour === "" ? "hh" : values?.whour,
                              minute:
                                values.wminute === "" ? "mm" : values?.wminute,
                            }).format()}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                whour: parseInt(moment(e).format("HH")),
                                wminute: parseInt(moment(e).format("m")),
                              });
                            }}
                            onClose={() => {
                              setShowTimeOption(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                onClick={() => {
                                  setShowTimeOption(true);
                                }}
                                {...params}
                                sx={{
                                  background: "#FFFFFF !important",
                                  ".MuiOutlinedInput-notchedOutline": {
                                    border: "none !important",
                                  },
                                }}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
              {values.happens === "daily" && (
                <>
                  <Grid
                    container
                    xs={12}
                    sx={{ marginTop: "25px" }}
                    alignItems={"center !important"}
                  >
                    <Grid item xs={12} md={3}>
                      <InputLabel htmlFor="my-input" className="conf-label">
                        On (EST)
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Box className="on-box-sty">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopTimePicker
                            ampm={false}
                            disableOpenPicker
                            open={showTimeOption}
                            value={moment({
                              hour: values?.dhour === "" ? "hh" : values?.dhour,
                              minute:
                                values?.dminute === "" ? "mm" : values?.dminute,
                            }).format()}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                dhour: parseInt(moment(e).format("HH")),
                                dminute: parseInt(moment(e).format("m")),
                              });
                            }}
                            onClose={() => {
                              setShowTimeOption(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                name="ontime"
                                id="ontime"
                                onClick={() => {
                                  setShowTimeOption(true);
                                }}
                                {...params}
                                sx={{
                                  background: "#FFFFFF !important",
                                  ".MuiOutlinedInput-notchedOutline": {
                                    border: "none !important",
                                  },
                                }}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
              {values.happens === "monthly" && (
                <>
                  <Grid
                    container
                    xs={12}
                    sx={{ marginTop: "25px" }}
                    alignItems={"center !important"}
                  >
                    <Grid item xs={12} md={3}>
                      <InputLabel htmlFor="my-input" className="conf-label">
                        Every
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        // className={
                        //   lostmonthChecked === true
                        //     ? 'monthselect disable'
                        //     : 'monthselect'
                        // }
                      >
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 1
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(1)}
                        >
                          <img src={Lock} className="lockIcon" />
                          01
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 2
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(2)}
                        >
                          <img src={Lock} className="lockIcon" />
                          02
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 3
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(3)}
                        >
                          <img src={Lock} className="lockIcon" />
                          03
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 4
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(4)}
                        >
                          <img src={Lock} className="lockIcon" />
                          04
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 5
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(5)}
                        >
                          <img src={Lock} className="lockIcon" />
                          05
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 6
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(6)}
                        >
                          <img src={Lock} className="lockIcon" />
                          06
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 7
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(7)}
                        >
                          <img src={Lock} className="lockIcon" />
                          07
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        // className={
                        //   lostmonthChecked === true
                        //     ? 'monthselect disable'
                        //     : 'monthselect'
                        // }
                      >
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 8
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(8)}
                        >
                          <img src={Lock} className="lockIcon" />
                          08
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 9
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(9)}
                        >
                          <img src={Lock} className="lockIcon" />
                          09
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 10
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(10)}
                        >
                          <img src={Lock} className="lockIcon" />
                          10
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 11
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(11)}
                        >
                          <img src={Lock} className="lockIcon" />
                          11
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 12
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(12)}
                        >
                          <img src={Lock} className="lockIcon" />
                          12
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 13
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(13)}
                        >
                          <img src={Lock} className="lockIcon" />
                          13
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 14
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(14)}
                        >
                          <img src={Lock} className="lockIcon" />
                          14
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        // className={
                        //   lostmonthChecked === true
                        //     ? 'monthselect disable'
                        //     : 'monthselect'
                        // }
                      >
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 15
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(15)}
                        >
                          <img src={Lock} className="lockIcon" />
                          15
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 16
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(16)}
                        >
                          <img src={Lock} className="lockIcon" />
                          16
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 17
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(17)}
                        >
                          <img src={Lock} className="lockIcon" />
                          17
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 18
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(18)}
                        >
                          <img src={Lock} className="lockIcon" />
                          18
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 19
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(19)}
                        >
                          <img src={Lock} className="lockIcon" />
                          19
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 20
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(20)}
                        >
                          <img src={Lock} className="lockIcon" />
                          20
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 21
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(21)}
                        >
                          <img src={Lock} className="lockIcon" />
                          21
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        // className={
                        //   lostmonthChecked === true
                        //     ? 'monthselect disable'
                        //     : 'monthselect'
                        // }
                      >
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 22
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(22)}
                        >
                          <img src={Lock} className="lockIcon" />
                          22
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 23
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(23)}
                        >
                          <img src={Lock} className="lockIcon" />
                          23
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 24
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(24)}
                        >
                          <img src={Lock} className="lockIcon" />
                          24
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 25
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(25)}
                        >
                          <img src={Lock} className="lockIcon" />
                          25
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 26
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(26)}
                        >
                          <img src={Lock} className="lockIcon" />
                          26
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 27
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(27)}
                        >
                          <img src={Lock} className="lockIcon" />
                          27
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 28
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(28)}
                        >
                          <img src={Lock} className="lockIcon" />
                          28
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        // className={
                        //   lostmonthChecked === true
                        //     ? 'monthselect disable'
                        //     : 'monthselect'
                        // }
                      >
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 29
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(29)}
                        >
                          <img src={Lock} className="lockIcon" />
                          29
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 30
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(30)}
                        >
                          <img src={Lock} className="lockIcon" />
                          30
                        </Box>
                        <Box
                          className={
                            parseInt(values.dateOfMonth) === 31
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(31)}
                        >
                          <img src={Lock} className="lockIcon" />
                          31
                        </Box>
                        <Box
                          className={
                            values.dateOfMonth === 90
                              ? "normalday active"
                              : "normalday"
                          }
                          onClick={() => handleDay(90)}
                        >
                          <img src={Lock} className="lockIcon" />
                        </Box>
                        <Box
                          className={
                            values.dateOfMonth === 53
                              ? "normalday active"
                              : "normalday"
                          }
                          // onClick={() => handleDay(25)}
                        >
                          <img src={Lock} className="lockIcon" />
                        </Box>
                        <Box
                          className={
                            values.dateOfMonth === 62
                              ? "normalday active"
                              : "normalday"
                          }
                          // onClick={() => handleDay(26)}
                        >
                          <img src={Lock} className="lockIcon" />
                        </Box>
                        <Box
                          className={
                            values.dateOfMonth === 72
                              ? "normalday active"
                              : "normalday"
                          }
                          // onClick={() => handleDay(72)}
                        >
                          {/* <img src={Lock} className="lockIcon" /> */}
                        </Box>
                        <Box
                          className={
                            values.dateOfMonth === 82
                              ? "normalday active"
                              : "normalday"
                          }
                          // onClick={() => handleDay()}
                        >
                          {/* <img src={Lock} className="lockIcon" /> */}
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    sx={{ marginTop: "25px" }}
                    alignItems={"center !important"}
                  >
                    <Grid item xs={12} md={3}>
                      <InputLabel
                        htmlFor="my-input"
                        className="conf-label"
                        sx={{
                          textAlign: "left",
                          fontWeight: "700",
                          fontSize: "16px",
                          mt: "10px",
                        }}
                      >
                        On (EST)
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Box className="on-box-sty">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopTimePicker
                            ampm={false}
                            disableOpenPicker
                            open={showTimeOption}
                            value={moment({
                              hour: values.mhour === "" ? "hh" : values?.mhour,
                              minute:
                                values.mminute === "" ? "mm" : values?.mminute,
                            }).format()}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                mhour: parseInt(moment(e).format("HH")),
                                mminute: parseInt(moment(e).format("m")),
                              });
                            }}
                            onClose={() => {
                              setShowTimeOption(false);
                            }}
                            renderInput={(params) => (
                              <TextField
                                onClick={() => {
                                  setShowTimeOption(true);
                                }}
                                {...params}
                                sx={{
                                  background: "#FFFFFF !important",
                                  ".MuiOutlinedInput-notchedOutline": {
                                    border: "none !important",
                                  },
                                }}
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid
                container
                xs={12}
                sx={{ marginTop: "25px" }}
                alignItems={"center !important"}
              >
                <Grid item xs={12}>
                  <InputLabel htmlFor="my-input" className="conf-label">
                    Notification Email Address
                  </InputLabel>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <BootstrapInput
                      name="notificationEmail"
                      id="notificationEmail"
                      value={values.notificationEmail}
                      onChange={handleChange}
                      placeholder="Enter the email address"
                    />
                    <FormHelperText error={true}>
                      {errors.notificationEmail && touched.notificationEmail
                        ? errors.notificationEmail
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  className="login-btn-group-bl"
                  size="medium"
                  onClick={handleSubmit}
                  disabled={loading}
                >
                  <CustomLoader loading={loading} />
                  SUBMIT
                </Button>
                <Button
                  className="login-btn-group-reset"
                  size="medium"
                  onClick={resetValues}
                  disabled={loading}
                >
                  RESET
                </Button>
                <Button
                  className="login-btn-group"
                  size="medium"
                  onClick={handleclose}
                >
                  CANCEL
                </Button>
              </Box>
            </form>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}
