import { Box, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import React from "react";
import CustomLoader from "../common/CustomLoader";
export default function ReportUnidentifyList(props) {
  const { unidentifyList, loading } = props;
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
      {loading ? (
        <>
          <Grid
            item
            xs={12}
            md={12}
            style={{ textAlign: "center", margin: "20px" }}
          >
            <CustomLoader loading={loading} />
          </Grid>
        </>
      ) : !loading && unidentifyList && unidentifyList.length == 0 ? (
        <Grid
          item
          xs={12}
          md={12}
          style={{ textAlign: "center", margin: "20px" }}
        >
          <Typography>No matching records found.</Typography>
        </Grid>
      ) : (
        <></>
      )}
      <div className="scr-activity reports">
        <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
          {!loading &&
            unidentifyList &&
            unidentifyList.map((r, i) => {
              return (
                <Box
                  className="bottom-box line9"
                  key={i}
                  style={{ marginBottom: "10px" }}
                >
                  <Box className="line"></Box>
                  <Typography variant="h4" className="report-title">
                    {/* {r.DisplayName} */}
                    {/* prod */}
                    {r.displayName}
                  </Typography>
                  <Typography className="report-content">
                    {/* {r.UserPrincipalName}| added on{" "}
                    {moment(new Date(r.CreatedDateTime)).format(
                      "MMMM DD, YYYY "
                    )} */}
                    {/* prod */}
                    {r.userPrincipalName}| added on{" "}
                    {moment(new Date(r.createdDateTime)).format(
                      "MMMM DD, YYYY "
                    )}
                  </Typography>
                </Box>
              );
            })}
        </Grid>
      </div>
    </Grid>
  );
}
