import {
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import React, { useState } from "react";
import CustomLoader from "../common/CustomLoader";
import NoRecordFound from "../common/NoRecordFound";
import { getSorting, stableSort } from "../common/function";
import MoreIcon from "../../images/more icon.png";
import styled from "@emotion/styled";
import remove from "../../images/remove.svg";
import { DELETE_USER_FROM_AZURE_URL } from "../common/ApiUrl";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import moment from "moment";
import DeleteMailbox from "./DeleteMailbox";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.15);",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export default function SharedList(props) {
  const { loading = false, list, search, getSharedMailList } = props;
  const [delLoading, setDelLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [userid, setUserId] = useState();
  const open = Boolean(anchorEl);
  const [removeFlag, setRemoveFlag] = useState(false);

  const removeFun = (e) => {
    setUserId(e.currentTarget.dataset.id);
    setRemoveFlag(true);
  };
  const successCall = () => {
    setRemoveFlag(false);
    getSharedMailList();
  };
  const handleCloseRemove = () => {
    setRemoveFlag(false);
    // getSharedMailList()
  };
  return (
    <>
      {loading ? (
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}></Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ textAlign: "center", margin: "40px" }}
          >
            <CustomLoader loading={loading} />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
      ) : list && Array.isArray(list[0]) && list[0].length === 0 ? (
        <NoRecordFound />
      ) : (
        <></>
      )}
      <div className="scr-activity">
        {console.log("list", list && list.length)}
        {!loading &&
          list &&
          Array.isArray(list[0]) &&
          stableSort(list[0], getSorting("asc", "DisplayName")).map((r, i) => {
            const {
              DisplayName = "",
              LastLogonTime = "",
              id = "",
              Identity = "",
              UserPrincipalName = "",
              LastLoggedOnUserAccount = "",
              OwnerADGuid = "",
              MailboxTypeDetail = "",
            } = r;
            return (
              <Grid
                container
                spacing={1}
                style={{
                  backgroundColor: "white",
                  marginTop: "15px",
                  // marginLeft: "20px",
                  padding: "6px 20px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                <Grid item xs={12} md={8} sx={{ display: "content" }}>
                  <Stack direction="row" spacing={2}>
                    <Typography
                      className="dis-name-typ"
                      style={{
                        // marginTop: "12px",
                        fontSize: "20px",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                    >
                      {DisplayName || "N/A"}
                    </Typography>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography
                      className="mail-text-btn"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        wordBreak: "break-word",
                      }}
                    >
                      {UserPrincipalName || "N/A"}
                    </Typography>
                    <Typography
                      className="mail-text-btn"
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                        wordBreak: "break-word",
                      }}
                    >
                      {/* Last login:02 June 2023,11.00 AM */}
                      {LastLogonTime
                        ? `Last Login: ${moment(new Date(LastLogonTime)).format(
                            "DD MMMM  YYYY"
                          )}`
                        : ""}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={1}
                  style={{
                    alignContent: "flex-end",
                    pr: "15px",
                    // marginTop: "15px",
                  }}
                ></Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{
                    alignContent: "flex-end",
                    pr: "15px",
                    // marginTop: "15px",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent={{ xs: "flex-start", md: "flex-end" }}
                  >
                    <IconButton
                      aria-label="delete"
                      style={{
                        backgroundColor: "none",
                        borderRadius: "10%",
                      }}
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      size="small"
                      data-id={Identity}
                      onClick={removeFun}
                    >
                      <img src={remove} />
                    </IconButton>
                  </Stack>
                </Grid>
                <StyledMenu
                  className="userl-popup"
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                >
                  <MenuItem disableRipple>Edit</MenuItem>
                  <MenuItem disableRipple>Delete</MenuItem>
                </StyledMenu>
              </Grid>
            );
          })}
        {removeFlag && (
          <DeleteMailbox
            open={removeFlag}
            handleclose={handleCloseRemove}
            successCall={successCall}
            id={userid}
          />
        )}
      </div>
    </>
  );
}
