import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Link, useLocation, useNavigate } from "react-router-dom";
import sidebarlogo from "../../images/sidel.png";
import usericon from "../../images/icon users.png";
import groupicon from "../../images/usericon.png";
import dasicon from "../../images/dasicon.png";
import acsicon from "../../images/acsicon.png";
import orsicon from "../../images/orsicon.png";
import reporticon from "../../images/reporticon.png";
import apricon from "../../images/apricon.png";
import actilogicon from "../../images/icon activity logs.png";
import Path from "../../images/Path.png";
import {
  ACCOUNT_SETTING_ROUTE,
  ACTIVITY_LOGS_ROUTE,
  APPROVAL_REQUESTS,
  DASHBOARD_ROUTE,
  GROUP_LIST_ROUTE,
  ORGANIZATION_SETTING_ROUTE,
  REPORTS_ROUTE,
  SYSTEM_USERS_ROUTE,
  USERS_ROUTE,
} from "../../constants/RoutePath";
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import sidebaradmin from "../../images/sidebaradmin.jpg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ListSubheader from "@mui/material/ListSubheader";
import { getUserDetails } from "../../redux/action";
import { USER_PROFILE_URL } from "../common/ApiUrl";
import {
  authHeader,
  CustomMessage,
  getDomain,
  getRole,
  getSideFlag,
  getTentant,
} from "../common/mainfunctions";
import { NAV_FLAG_TYPE, USER_DETAILS_FLAG } from "../../redux/action/ActionType";
import API from "../common/API";
import AzureAD, { AuthenticationState } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import logofficon from "../../images/logout.png";
import { Button } from "@mui/material";
import mailboxicon from "../../images/mailbox.svg";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import monitor from "../../images/monitor.svg";
import MissingPermission from "../organizationSetting/Permissions/MissingPermission";
import DeviceAlert from "../organizationSetting/Permissions/DeviceAlert";
import { useSnackbar } from "notistack";
import * as microsoftTeams from "@microsoft/teams-js";
import PermissionAlert from "../organizationSetting/Permissions/PermissionAlert";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const DrawerFooter = styled("div")(({ theme }) => ({
  // position: "fixed",
  alignItems: "left",
  // width: `calc(${drawerWidth}px - 10px)`,
  bottom: 0,
  // margin: "0px 30px 20px 10px",
  marginTop: "auto",
}));
function Sidebar() {
  //   const styleclasses = useStyles();
  const userDet = useSelector((ud) => ud.UserDetailsReducer);
  const {
    msIntegrateFlag = "",
    disconnectFlag = "",
    permissionAddedFlag = "",
    powershellPermissionFlag = "",
    intuneManagement = "",
    pax8IntegrateFlag = "",
    permissionUpdatedFlag = 3,
    newUpdateFlag = "",
    botAppInstallFlag = "",
  } = userDet || {};
  const [orgopen, setOrgOpen] = useState(false);
  const [openmis, setOpenMis] = useState(true);
  const handleClose = () => {
    setOpenMis(false);
  };
  const handleClick = () => {
    setOrgOpen(!orgopen);
  };
  let domain = getDomain();
  let listsData = [
    { text: "Dashboard", routepath: DASHBOARD_ROUTE, ic: dasicon },
    { text: "Account Settings", routepath: ACCOUNT_SETTING_ROUTE, ic: acsicon },
    {
      text: "Organization Settings",
      routepath: ORGANIZATION_SETTING_ROUTE,
      ic: orsicon,
    },
    { text: "System Users", routepath: SYSTEM_USERS_ROUTE, ic: usericon },
    { text: "Approval Requests", routepath: APPROVAL_REQUESTS, ic: apricon },
    { text: "Users", routepath: USERS_ROUTE, ic: usericon },
    { text: "Groups", routepath: USERS_ROUTE, ic: usericon },
    { text: "Reports", routepath: REPORTS_ROUTE, ic: reporticon },
  ];
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const navigation = useNavigate();
  const [open, setOpen] = React.useState(true);
  let role = localStorage.getItem("ROLE");
  const [mobFlag, setMobFlag] = useState(false);
  const [perFlag, setPerFlag] = useState(true);
  const handlePerClose = () => {
    setPerFlag(false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const oflag = useSelector((of) => of.NavbarFlagReducer);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (location.pathname == "/organization") {
      setOrgOpen(true);
    } else if (location.pathname == "/system-users") {
      setOrgOpen(true);
    } else {
      setOrgOpen(false);
    }
  }, [location.pathname]);
  const sidebarFun = (e) => {
     dispatch({
          type: NAV_FLAG_TYPE,
          payload: false,
        });
    navigation(e.currentTarget.dataset.rou);
  };
  const sidebarno = (e) => {

    console.log("clicked me");
  };
  const getUserDetailsFun = async () => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: USER_PROFILE_URL,
    };
    try {
      const response = await API(options);
      if (response.status === 200) {
        const { status, data } = response.data;
        localStorage.setItem("ORGID", data.organizationId);
        dispatch({
          type: USER_DETAILS_FLAG,
          payload: data,
        });
      }
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
      }
    }
  };

  useEffect(() => {
    getUserDetailsFun();
  }, []);
  const handleBeforeUnload = (event) => {
    // Clear LocalStorage only when the logout process is successful
    if (localStorage.getItem("Remove") == 1) {
      console.log("remove", localStorage.getItem("Remove"));
      // localStorage.clear();
    }
  };

  React.useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  let fe = sessionStorage.getItem("Flag");

  useEffect(() => {
    const handleMessage = (event) => {
      // console.log("logoutttt", event.data.token, event.data.type, fe);
      const { data } = event;
      if (data && data.type === "logout") {
        console.log("day", data.type, fe);
        console.log("ssss", authHeader());
        if (localStorage.getItem("AUTH_TOKEN") == null) {
          const cookies = document.cookie.split(";");

          cookies.forEach((cookie) => {
            const eqPos = cookie.indexOf("=");
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie =
              name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
          });
          console.log(getSideFlag());
          localStorage.clear();
          if (window.location !== window.parent.location) {
            localStorage.setItem("SIDE_FLAG", true);
            window.open("https://hrassist.app/signup", "_self");
            microsoftTeams.initialize();
          } else {
            console.log("App is running outside of Microsoft Teams");
            localStorage.setItem("SIDE_FLAG", false);
            navigation("/signin");
            // window.open('http://localhost:3000/signup','_self')
          }
        } else {
        }
        console.log("data", data);
        event.source.postMessage({ type: "logout" }, event.origin);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [fe]);
  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (
      /android|iPhone|iPad|iPod/i.test(userAgent) ||
      window.location.href.includes("teams.microsoft.com")
    );
  };
  useEffect(() => {
    if (isMobile()) {
      setMobFlag(true);
      console.log("Running inside Microsoft Teams on Mobile");
      // Handle mobile-specific logic
    }
  }, []);
  function clearCookie(name) {
    document.cookie = `${name}=; path=/; domain=.hrassist.app; expires=Thu, 01 Jan 1970 00:00:00 UTC; samesite=lax`;
  }
  const clearcall = async () => {
    sessionStorage.setItem("Flag", 0);
    localStorage.setItem("Flag", 0);
    let tid = getTentant();

    if (mobFlag === true) {
      // CustomMessage("inside mobile", "success", enqueueSnackbar);
      clearCookie("userToken");
      clearCookie("userdomain");

      // Directly redirect for mobile
      window.location.href = `https://login.microsoftonline.com/${tid}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirecturl}`;

      localStorage.clear();
    } else {
      const originSubdomain = window.location.origin;

      const popup = window.open(
        "",
        "_blank",
        `width=${window.innerWidth},height=${window.innerHeight},toolbar=no,location=no,menubar=no,titlebar=no`
      );

      if (!popup) {
        console.error("Popup blocked!");
        return;
      }
      popup.location.href = `https://login.microsoftonline.com/${tid}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirecturl}`;
      localStorage.clear();

      popup.onload = () => {
        popup.location.href = `https://login.microsoftonline.com/${tid}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirecturl}`;
        localStorage.clear();
      };

      window.addEventListener("message", (event) => {
        console.log(event);
      });
      const redirectUrl1 = encodeURIComponent(redirecturl);

      // const logoutUrl = `https://login.microsoftonline.com/${tid}/oauth2/v2.0/logout?post_logout_redirect_uri=${redirectUrl1}
      //                   &state=${encodeURIComponent(originSubdomain)}`;

      // window.location.href = logoutUrl;
      clearCookie("userToken");
      localStorage.clear();
    }
  };

  return (
    <>
      <CssBaseline />

      <Drawer className="sidebar" variant="permanent" open={oflag}>
        {/* <DrawerHeader>
            <IconButton>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader> */}
        <Divider />
        <AzureAD provider={authProvider}>
          {({ login, logout, authenticationState, error, accountInfo }) => {
            switch (authenticationState) {
              case AuthenticationState.Authenticated:
                return (
                  <>
                    <Box
                      onClick={(e) => {
                        e.preventDefault();
                        // localStorage.clear();
                        logout();
                      }}
                    >
                      <img
                        src={sidebarlogo}
                        alt="logo"
                        style={{
                          hight: "80px",
                          width: "80px",
                          marginTop: "20px",
                          marginLeft: "80px",
                        }}
                      />
                    </Box>
                  </>
                );
              case AuthenticationState.Unauthenticated:
                return (
                  <>
                    <Box
                      onClick={(e) => {
                        e.preventDefault();
                        // localStorage.clear();
                        logout();
                      }}
                    >
                      <img
                        src={sidebarlogo}
                        alt="logo"
                        style={{
                          hight: "80px",
                          width: "80px",
                          marginTop: "20px",
                          marginLeft: "80px",
                        }}
                      />
                    </Box>
                  </>
                );
            }
          }}
        </AzureAD>

        <Typography
          style={{
            width: "41px",
            height: "20px",
            top: "120px",
            marginLeft: "89.5px",
            color: "#FFFFFF",
          }}
        >
          {role}
        </Typography>
        {/* <img src={Path} className="side-path"></img> */}
        <List className="sidebar-text">
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            // data-rou={`${domain}/dashboard`}
            className={
              location.pathname.toString().includes("/dashboard")
                ? "menu-list1 active"
                : ""
            }
            data-rou={"/dashboard"}
            onClick={
              getRole() == "Admin"
                ? msIntegrateFlag == 0 ||
                  disconnectFlag == 1 ||
                  permissionAddedFlag == null ||
                  powershellPermissionFlag == null ||
                  botAppInstallFlag != true ||
                  botAppInstallFlag == 0
                  ? sidebarno
                  : sidebarFun
                : sidebarFun
            }
          >
            {/* <Link to={routepath}> */}
            <ListItemButton
              disabled={
                role == "Admin"
                  ? msIntegrateFlag == 0 ||
                    disconnectFlag == 1 ||
                    permissionAddedFlag == null ||
                    powershellPermissionFlag == null ||
                    botAppInstallFlag != true ||
                    botAppInstallFlag == 0
                    ? true
                    : false
                  : false
              }
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                <img src={dasicon} />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ color: "white" }}
              />
            </ListItemButton>
            {/* </Link> */}
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            data-rou={"/account"}
            className={
              location.pathname.toString().includes("/account")
                ? "menu-list1 active"
                : ""
            }
            // onClick={msIntegrateFlag==0&&getRole()=="Admin"?sidebarno:sidebarFun}

            onClick={sidebarFun}
          >
            {/* <Link to={routepath}> */}
            <ListItemButton
              // disabled={role=="Admin"?
              // msIntegrateFlag==0?true:false:false}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                <img src={acsicon} />
              </ListItemIcon>
              <ListItemText
                primary="Account Settings"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ color: "white" }}
              />
            </ListItemButton>
            {/* </Link> */}
          </ListItem>
          {role == "Admin" ? (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              data-rou={"/organization"}
              onClick={sidebarFun}
              className={
                location.pathname.toString().includes("/organization")
                  ? "menu-list1 active"
                  : ""
              }
            >
              {/* <Link to={routepath}> */}
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  <img src={orsicon} />
                </ListItemIcon>
                <ListItemText
                  primary="Organization Settings"
                  sx={{ opacity: open ? 1 : 0 }}
                  style={{ color: "white" }}
                />
              </ListItemButton>
              {/* </Link> */}
            </ListItem>
          ) : (
            <></>
          )}
          {/* sub menu */}
          {/* <ListItem 
           disablePadding sx={{ display: "block" }} 


          >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  onClick={handleClick}

                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      
                    }}
                    
                  >
                    <img src={orsicon}/>
                  </ListItemIcon>
                  <ListItemText 
                  primary="Organization Settings"
                   sx={{ opacity: open ? 1 : 0 }}
                    style={{color:'white'}}

                    />
        {orgopen ? <ExpandLess /> : <ExpandMore />}

                </ListItemButton>
                <Collapse in={orgopen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 4 }} onClick={sidebarFun} data-rou={ORGANIZATION_SETTING_ROUTE}>
            <ListItemIcon>
              <img src={orsicon} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}
                disabled={disconnectFlag==1?true:false}

           onClick={sidebarFun} data-rou={SYSTEM_USERS_ROUTE}>
            <ListItemIcon>
              <img src={orsicon} />
            </ListItemIcon>
            <ListItemText primary="System Users" />
          </ListItemButton>
        </List>
      </Collapse>
              </ListItem> */}
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            data-rou={"/approvalrequests"}
            className={
              location.pathname.toString().includes("/approvalrequests")
                ? "menu-list1 active"
                : ""
            }
            onClick={
              getRole() == "Admin"
                ? msIntegrateFlag == 0 ||
                  disconnectFlag == 1 ||
                  permissionAddedFlag == null ||
                  powershellPermissionFlag == null ||
                  botAppInstallFlag != true ||
                  botAppInstallFlag == 0
                  ? sidebarno
                  : sidebarFun
                : sidebarFun
            }
          >
            {/* <Link to={routepath}> */}
            <ListItemButton
              disabled={
                role == "Admin"
                  ? msIntegrateFlag == 0 ||
                    disconnectFlag == 1 ||
                    permissionAddedFlag == null ||
                    powershellPermissionFlag == null ||
                    botAppInstallFlag != true ||
                    botAppInstallFlag == 0
                    ? true
                    : false
                  : false
              }
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                <img src={apricon} />
              </ListItemIcon>
              <ListItemText
                primary="User Requests"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ color: "white" }}
              />
            </ListItemButton>
            {/* </Link> */}
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            data-rou={"/users"}
            onClick={
              getRole() == "Admin"
                ? msIntegrateFlag == 0 ||
                  disconnectFlag == 1 ||
                  permissionAddedFlag == null ||
                  powershellPermissionFlag == null ||
                  botAppInstallFlag != true ||
                  botAppInstallFlag == 0
                  ? sidebarno
                  : sidebarFun
                : sidebarFun
            }
            className={
              location.pathname.toString().includes("/users")
                ? "menu-list1 active"
                : ""
            }
          >
            {/* <Link to={routepath}> */}
            <ListItemButton
              disabled={
                role == "Admin"
                  ? msIntegrateFlag == 0 ||
                    disconnectFlag == 1 ||
                    permissionAddedFlag == null ||
                    powershellPermissionFlag == null ||
                    botAppInstallFlag != true ||
                    botAppInstallFlag == 0
                    ? true
                    : false
                  : false
              }
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                <img src={usericon} />
              </ListItemIcon>
              <ListItemText
                primary="Users"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ color: "white" }}
              />
            </ListItemButton>
            {/* </Link> */}
          </ListItem>
          {/* {getRole() == "Admin" && intuneManagement == 1 ? (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              data-rou={"/device"}
              onClick={
                getRole() == "Admin"
                  ? msIntegrateFlag == 0 ||
                    disconnectFlag == 1 ||
                    permissionAddedFlag == null ||
                    powershellPermissionFlag == null
                    ? sidebarno
                    : sidebarFun
                  : sidebarFun
              }
              className={
                location.pathname.toString().includes("/device")
                  ? "menu-list1 active"
                  : ""
              }
            >
              <ListItemButton
                disabled={
                  role == "Admin"
                    ? msIntegrateFlag == 0 ||
                      disconnectFlag == 1 ||
                      permissionAddedFlag == null ||
                      powershellPermissionFlag == null
                      ? true
                      : false
                    : false
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src={monitor} />
                </ListItemIcon>
                <ListItemText
                  primary="Devices"
                  sx={{ opacity: open ? 1 : 0 }}
                  style={{ color: "white" }}
                />
              </ListItemButton>
            </ListItem>
          ) : (
            <></>
          )} */}
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            data-rou={"/groups"}
            onClick={
              getRole() == "Admin"
                ? msIntegrateFlag == 0 ||
                  disconnectFlag == 1 ||
                  permissionAddedFlag == null ||
                  powershellPermissionFlag == null ||
                  botAppInstallFlag != true ||
                  botAppInstallFlag == 0
                  ? sidebarno
                  : sidebarFun
                : sidebarFun
            }
            className={
              location.pathname.toString().includes("/groups")
                ? "menu-list1 active"
                : ""
            }
          >
            {/* <Link to={routepath}> */}
            <ListItemButton
              disabled={
                role == "Admin"
                  ? msIntegrateFlag == 0 ||
                    disconnectFlag == 1 ||
                    permissionAddedFlag == null ||
                    powershellPermissionFlag == null ||
                    botAppInstallFlag != true ||
                    botAppInstallFlag == 0
                    ? true
                    : false
                  : false
              }
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                <img src={groupicon} />
              </ListItemIcon>
              <ListItemText
                primary="Groups"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ color: "white" }}
              />
            </ListItemButton>
            {/* </Link> */}
          </ListItem>
          {role == "Admin" ? (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              data-rou={"/mailbox"}
              onClick={
                getRole() == "Admin"
                  ? msIntegrateFlag == 0 ||
                    disconnectFlag == 1 ||
                    permissionAddedFlag == null ||
                    powershellPermissionFlag == null ||
                    botAppInstallFlag != true ||
                    botAppInstallFlag == 0
                    ? sidebarno
                    : sidebarFun
                  : sidebarFun
              }
              className={
                location.pathname.toString().includes("/mailbox")
                  ? "menu-list1 active"
                  : ""
              }
            >
              {/* <Link to={routepath}> */}
              <ListItemButton
                disabled={
                  role == "Admin"
                    ? msIntegrateFlag == 0 ||
                      disconnectFlag == 1 ||
                      permissionAddedFlag == null ||
                      powershellPermissionFlag == null ||
                      botAppInstallFlag != true ||
                      botAppInstallFlag == 0
                      ? true
                      : false
                    : false
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  <img
                    src={mailboxicon}
                    style={{ height: "16px", width: "20px" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Mailbox Maintenance"
                  sx={{ opacity: open ? 1 : 0 }}
                  style={{ color: "white" }}
                />
              </ListItemButton>
              {/* </Link> */}
            </ListItem>
          ) : (
            <></>
          )}
          {role == "Admin" ? (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              data-rou={"/reports"}
              onClick={
                (msIntegrateFlag == 0 ||
                  permissionAddedFlag == null ||
                  powershellPermissionFlag == null ||
                  disconnectFlag == 1 ||
                  botAppInstallFlag != true ||
                  botAppInstallFlag == 0) &&
                getRole() == "Admin"
                  ? sidebarno
                  : sidebarFun
              }
              className={
                location.pathname.toString().includes("/reports")
                  ? "menu-list1 active"
                  : ""
              }
            >
              {/* <Link to={routepath}> */}
              <ListItemButton
                disabled={
                  role == "Admin"
                    ? msIntegrateFlag == 0 ||
                      disconnectFlag == 1 ||
                      permissionAddedFlag == null ||
                      powershellPermissionFlag == null ||
                      botAppInstallFlag != true ||
                      botAppInstallFlag == 0
                      ? true
                      : false
                    : false
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  <img src={reporticon} />
                </ListItemIcon>
                <ListItemText
                  primary="Reports"
                  sx={{ opacity: open ? 1 : 0 }}
                  style={{ color: "white" }}
                />
              </ListItemButton>
              {/* </Link> */}
            </ListItem>
          ) : (
            <></>
          )}
          {role == "Admin" ? (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              data-rou={"/activitylogs"}
              onClick={
                (msIntegrateFlag == 0 ||
                  permissionAddedFlag == null ||
                  powershellPermissionFlag == null ||
                  disconnectFlag == 1 ||
                  botAppInstallFlag != true ||
                  botAppInstallFlag == 0) &&
                getRole() == "Admin"
                  ? sidebarno
                  : sidebarFun
              }
              className={
                location.pathname.toString().includes("/activitylogs")
                  ? "menu-list1 active"
                  : ""
              }
            >
              {/* <Link to={routepath}> */}
              <ListItemButton
                disabled={
                  role == "Admin"
                    ? msIntegrateFlag == 0 ||
                      disconnectFlag == 1 ||
                      permissionAddedFlag == null ||
                      powershellPermissionFlag == null ||
                      botAppInstallFlag != true ||
                      botAppInstallFlag == 0
                      ? true
                      : false
                    : false
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                  <img src={actilogicon} />
                </ListItemIcon>
                <ListItemText
                  primary="Activity Logs"
                  sx={{ opacity: open ? 1 : 0 }}
                  style={{ color: "white" }}
                />
              </ListItemButton>
              {/* </Link> */}
            </ListItem>
          ) : (
            <></>
          )}
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={clearcall}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img src={logofficon} />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                sx={{ opacity: open ? 1 : 0 }}
                style={{ color: "white" }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        {/* <DrawerFooter>
          <List>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={clearcall}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src={logofficon} />
                </ListItemIcon>
                <ListItemText
                  primary="Logout"
                  sx={{ opacity: open ? 1 : 0 }}
                  style={{ color: "white" }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </DrawerFooter> */}
      </Drawer>
      {console.log("permis", permissionUpdatedFlag)}

      {getRole() == "Admin" ? (
        (permissionUpdatedFlag != "" ||
          permissionUpdatedFlag == 0 ||
          permissionUpdatedFlag != true) &&
        getRole() == "Admin" ? (
          <PermissionAlert
            open={
              permissionUpdatedFlag == 0 && permissionUpdatedFlag != 3
                ? true
                : false
            }
            handleClose={handlePerClose}
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {/* {
   <PermissionAlert open={open} handleClose={handlePerClose}/>
  } */}
      {newUpdateFlag != 1 && newUpdateFlag != "" && getRole() == "Admin" ? (
        <DeviceAlert
          open={openmis}
          handleClose={handleClose}
          // azureFlag={azureFlag}
          // setAzureFlag={setAzureFlag}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Sidebar;
