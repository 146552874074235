import {
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TablePagination,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import filtericon from "../../images/filter-new.png";
import SearchIcon from "@mui/icons-material/Search";
import addicon from "../../images/addicon.png";
import MoreIcon from "../../images/more icon.png";
import delIcon from "../../images/deleteicon.png";
import editIcon from "../../images/editIcon.png";
import styled from "@emotion/styled";
import {
  ALL_USERS_LIST_URL,
  APPROVER_ACC_USER_LIST,
  DELETE_USER_FROM_AZURE_URL,
  GET_USER_BY_ID,
  REQUESTER_ACC_USER_LIST,
  USERS_ENABLE_WITH_REMOVE_FORWARD_URL,
  USERS_LIST_FROM_AZURE_FOR_USERS_URL,
} from "../common/ApiUrl";
import {
  CustomMessage,
  authHeader,
  getOrgId,
  getRole,
  getSideFlag,
} from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import oval from "../../images/Oval-white.png";
import AddNewUserPopup from "./AddNewUserPopup";
import { useDispatch } from "react-redux";
import {
  getAzurerDomainList,
  getDepartmentList,
  getDistributionList,
  getGroupList,
  getJobTitleList,
  getLicenceList,
  getOfficeList,
  getRoleList,
} from "../../redux/action";
import ViewUserDetailsPopup from "./ViewUserDetailsPopup";
import Switch from "@mui/material/Switch";
import CustomLoader from "../common/CustomLoader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import UserDisableConfirmPopup from "./UserDisableConfirmPopup";
import UsersFilter from "./UsersFilter";
import clrfilter from "../../images/clearfilter.svg";
import { getSorting, stableSort } from "../common/function";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteUser from "./DeleteUser";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { debounce } from "underscore";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: "0px 15px 50px 0px rgba(0, 0, 0, 0.15);",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const initialValues = {
  userName: "",
  email: "",
  department: "",
  jobtitle: "",
  office: "",
  group: "",
};
export default function UsersList() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [adduser, setAddUSer] = useState(false);
  const [viewOpen, setView] = useState(false);
  const open = Boolean(anchorEl);
  const [uid, setUid] = useState();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);
  const [openPop, setOpenPop] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [enableid, setId] = useState();
  const [userPrinName, setuserPrincipalName] = useState();
  const [deleteid, setDeleteId] = useState();
  const [openfil, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(initialValues);
  const [editFlag, setEditFlag] = useState(true);
  const [loadingdis, setLoadingDis] = useState(false);
  const Navigation = useNavigate();
  const tok = authHeader();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [total, setTotal] = useState(0);
  const [pageurl, setPageUrl] = useState();
  const [toFirst, settoFirst] = useState();
  let scrollRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);
  const scrollTopRef = useRef(0);

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      scrollRef.current.scrollTop = parseInt(scrollPosition);
    }
  }, []);

  // useEffect(() => {
  //   if (data.length && scrollRef.current) {
  //     scrollRef.current.scrollTo(0, scrollTopRef.current);
  //   }
  // }, [data]);
  const scrollCall = () => {
    sessionStorage.setItem("scrollPosition", scrollRef.current.scrollTop);

    console.log("call with", toFirst, data.length);
    if (toFirst != data.length) {
      setPage(page + 1);
      setLoading(true);
    } else {
    }
  };
  const onScrollHandler = (e) => {
    console.log("scroll handle");
    const { target } = e;
    console.log("scroll handle1", target.scrollHeight);

    const rmHeight = target.scrollHeight - target.scrollTop;
    const clHeight = target.clientHeight;
    console.log(rmHeight, clHeight);
    if (!loading) {
      if (rmHeight === clHeight && toFirst !== data.length) {
        console.log("called");
        scrollCall();
      }
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleScroll = (event) => {
    setDeleteId();

    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    // sessionStorage.setItem("scrollPosition", scrollRef.current.scrollTop);
    sessionStorage.setItem("scrollPosition", scrollTop);
    setScrollTop(scrollTop);
    // Check if user has scrolled to the bottom
    console.log(
      "scrollls",
      scrollHeight,
      scrollTop,
      clientHeight,
      scrollHeight - scrollTop
    );
    if (scrollHeight - scrollTop === clientHeight) {
      // Load more data or perform other actions
      console.log("Scrolled to the bottom", pageurl);
      // For example, you can fetch more data here
      // fetchMoreData();

      if (pageurl) {
        getUsersList();
      }
    } else {
      console.log("Scrolled not to the bottom");
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // useEffect(() => {
  //   window.scrollTo(0, "smooth");
  //   const scrollPosition = sessionStorage.getItem("scrollPosition");
  //   console.log("scrollposition", scrollPosition);
  //   if (data.length) {
  //     if (scrollPosition) {
  //       scrollRef.current.scrollTo(0, parseInt(scrollTop));
  //       // sessionStorage.removeItem("scrollPosition");
  //     }
  //   }
  // }, [data]);
  // useEffect(() => {
  //   const hash = window.location.hash;
  //   const hashParams = new URLSearchParams(hash.substring(1));
  //   const code = hashParams.get("code");
  //   console.log("code", code);
  // }, [window.location.hash]);
  useEffect(() => {
    if (code) {
      console.log("code", code);
      // setAddUSer(true);
    }
  }, []);
  console.log("scroll pos", scrollTop);
  const handleClickOpen = (e) => {
    const id = e.currentTarget.dataset.enableid;
    const userprinname = e.currentTarget.dataset.userprinname;
    setId(id);
    setuserPrincipalName(userprinname);
    setOpenPop(true);
  };

  const handleCloseDelyes = () => {
    setOpenDel(false);
  };
  const handleCloseDel = () => {
    setOpenDel(false);
  };

  const setShownFun = (event) => {
    setAnchorEl(event.currentTarget);
    setDeleteId(event.currentTarget.dataset.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setSearchFun = (e) => {
    // setLoading(true);
    setData([]);
    setSearch(e.target.value);
    setPageUrl();
  };
  // const getUsersList = async () => {
  //   const { userName, email, department, jobtitle, office, group } = filter;
  //   setLoading(true);
  //   const options = {
  //     method: "GET",
  //     url:
  //       getRole() == "Admin"
  //         ? ALL_USERS_LIST_URL
  //         : getRole() == "Requester"
  //         ? REQUESTER_ACC_USER_LIST
  //         : APPROVER_ACC_USER_LIST,
  //     headers: authHeader(),
  //     params: {
  //       search: search,
  //       userNameFilter: userName ? userName : null,
  //       emailFilter: email ? email : null,
  //       departmentFilter: department ? department : null,
  //       officeFilter: office ? office : null,
  //       jobTitleFilter: jobtitle ? jobtitle : null,
  //       groupFilter: group ? group : null,
  //       pageLimit: 10,
  //       paginationUrl: pageurl,
  //     },
  //   };
  //   try {
  //     var apiResponse = await API(options);
  //     const { status, data } = apiResponse.data;
  //     if (status === "Success") {
  //       const { "@odata.count": count, "@odata.nextLink": pageurl } = data;
  //       setTotal(count);
  //       if (count) {
  //         settoFirst(count);
  //       }
  //       if (pageurl) {
  //         setPageUrl(pageurl);
  //         // getUsersList();
  //       } else {
  //         setPageUrl();
  //       }
  //       if (deleteid) {
  //         console.log("1 st if", deleteid);
  //         let filresult = data.value.filter((r) => r.id != deleteid);
  //         setData(filresult);
  //       } else if (search != "" && !pageurl) {
  //         console.log("2 nd if search");

  //         setData(data.value);
  //       } else {
  //         console.log("3 rt elseif");

  //         // setData((prev) => prev.concat(data.value));
  //         setData((prev) => {
  //           // Check if each record in data.value already exists in prev (existing data)
  //           console.log("prev", prev);
  //           const newData = data.value.filter(
  //             (newValue) =>
  //               !prev.some((prevValue) => prevValue.id === newValue.id)
  //           );
  //           return prev.concat(newData);
  //         });
  //       }
  //       if (scrollRef.current) {
  //         scrollRef.current.scrollTo(0, scrollRef.current.scrollTop);
  //       }
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       const { message } = error.response.data;
  //       CustomMessage(message, "error", enqueueSnackbar);
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const getUsersList = async (search, linknxt) => {
    const { userName, email, department, jobtitle, office, group } = filter;
    setLoading(true);
    const options = {
      method: "GET",
      url:
        getRole() == "Admin"
          ? ALL_USERS_LIST_URL
          : getRole() == "Requester"
          ? REQUESTER_ACC_USER_LIST
          : APPROVER_ACC_USER_LIST,
      headers: authHeader(),
      params: {
        search: search,
        userNameFilter: userName ? userName : null,
        emailFilter: email ? email : null,
        departmentFilter: department ? department : null,
        officeFilter: office ? office : null,
        jobTitleFilter: jobtitle ? jobtitle : null,
        groupFilter: group ? group : null,
        pageLimit: 10,
        paginationUrl: linknxt,
      },
    };
    try {
      var apiResponse = await API(options);
      const { status, data } = apiResponse.data;
      if (status === "Success") {
        const { "@odata.count": count, "@odata.nextLink": pageurl } = data;
        setTotal(count);
        if (count) {
          settoFirst(count);
        }
        if (pageurl) {
          getUsersList("", pageurl);
          setPageUrl(pageurl);
          // getUsersList();
        } else {
          setPageUrl();
        }
        //  if (deleteid) {
        //    console.log("1 st if", deleteid);
        //    let filresult = data.value.filter((r) => r.id != deleteid);
        //    setData(filresult);
        //  } else if (search != "" && !pageurl) {
        //    console.log("2 nd if search");

        //    setData(data.value);
        //  }
        //  else {
        console.log("3 rt elseif");

        // setData((prev) => prev.concat(data.value));
        setData((prev) => {
          // Check if each record in data.value already exists in prev (existing data)
          console.log("prev", prev);
          const newData = data.value.filter(
            (newValue) =>
              !prev.some((prevValue) => prevValue.id === newValue.id)
          );
          return prev.concat(newData);
        });
        //  }
        if (scrollRef.current) {
          scrollRef.current.scrollTo(0, scrollRef.current.scrollTop);
        }
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        CustomMessage(message, "error", enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      getUsersList();
    }, 500);
  }, [filter]);
  const delaySearch = useRef(
    debounce((search) => getUsersList(search, ""), 500)
  ).current;
  useEffect(() => {
    delaySearch(search);
  }, [search]);
  useEffect(() => {
    dispatch(getDepartmentList(getOrgId()));
    dispatch(getOfficeList(getOrgId()));
    dispatch(getJobTitleList(getOrgId()));
    dispatch(getAzurerDomainList());
    dispatch(getDistributionList());
  }, []);
  const addUserFun = () => {
    //     window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
    // client_id=dd6fddc2-99f3-4cbe-a9cc-045a935dd24a
    // &client_secret=aSk8Q~xsJB2yXOMGoCRsld2jvt0.x.j47lfuvbmB
    // &response_type=code
    // &redirect_uri=http://localhost:3000/redirect
    // &response_mode=query
    // &scope=offline_access%20user.read%20mail.read
    // &state=12345`;
    setAddUSer(!adduser);
  };
  const initiateAuthentication = async () => {
    const config = {
      method: "GET",
      headers: authHeader(),
      url: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
    };
    try {
      // Make a request to the authentication endpoint
      const response = await API(config);
      console.log("res", response);
    } catch (error) {
      // Log the error for debugging
      console.error("Error during authentication:", error);
    }
  };
  useEffect(() => {
    // initiateAuthentication();
  }, []);
  useEffect(() => {
    if (!tok) {
      Navigation("/signin");
    } else {
      console.log("tok", `*****${tok}`);
    }
  }, [tok]);
  //   useEffect(() => {
  //     window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
  // client_id=dd6fddc2-99f3-4cbe-a9cc-045a935dd24a
  // &client_secret=aSk8Q~xsJB2yXOMGoCRsld2jvt0.x.j47lfuvbmB
  // &response_type=code
  // &response_mode=query
  // &scope=offline_access%20user.read%20mail.read
  // &state=12345`;
  //   }, []);
  const handleViewUser = async (e) => {
    setUid(e.currentTarget.dataset.id);
    setAddUSer(false);
    setEditFlag(e.currentTarget.dataset.flag);
    setView(!viewOpen);
    setAnchorEl(null);
  };

  const hc = () => {
    setView(!viewOpen);
  };

  const handleCloseConfirm = () => {
    setOpenPop(false);
    setPageUrl();
    setTimeout(() => {
      getUsersList();
    }, 5000);
  };
  const handleDelete = async (e) => {
    const id = e.currentTarget.dataset.id;
    setOpenDel(true);
    setAnchorEl(null);
  };

  const handleEnableUSer = async (e) => {
    setLoadingDis(true);
    const { enableid, userprinname } = e.currentTarget.dataset;
    const config = {
      method: "GET",
      url: USERS_ENABLE_WITH_REMOVE_FORWARD_URL,
      headers: authHeader(),
      params: {
        disableUserId: enableid,
        disableUserPrincipalName: userprinname,
        removeForwardingStatus: "yes",
      },
    };
    try {
      const response = await API(config);
      const { data, message, status } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);

        setTimeout(() => {
          getUsersList();
        }, 5000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDis(false);
    }
  };
  const openFilter = () => {
    setOpenFilter(!openfil);
  };
  const clearFilter = () => {
    setFilter(initialValues);
  };
  const isAnyFilterApplied = Object.values(filter).some(
    (value) => value !== ""
  );
  const deleteclose = () => {
    setOpenDel(false);
    setDeleteId();
  };
  const deletecloseSuccess = () => {
    setPageUrl();

    setOpenDel(false);
    setTimeout(() => {
      getUsersList();
    }, 1000);
  };
  return (
    <>
      <Grid
        sx={{ mt: getSideFlag() == "true" ? 0 : 5 }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      >
        <Grid item xs={12} md={6}>
          <Typography
            className="group-head"
            // style={{ marginLeft: "20px" }}
          >
            Users List
            <Tooltip title="Filter">
              <IconButton
                aria-label="delete"
                sx={{ padding: "10px" }}
                size="small"
                onClick={openFilter}
              >
                <img src={filtericon} />
              </IconButton>
            </Tooltip>
            {isAnyFilterApplied && (
              <Tooltip title="Clear filter">
                <IconButton
                  aria-label="delete"
                  sx={{ padding: "10px" }}
                  size="small"
                  onClick={clearFilter}
                >
                  <img src={clrfilter} />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          sx={{ display: "flex", textAlign: "right" }}
        >
          <Paper
            //   component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "-webkit-fill-available",
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "20px",
              height: "50px",
              boxShadow: "none !important",
              marginLeft: { xs: "0px !important", md: "0px !important" },
            }}
          >
            <InputBase
              sx={{ flex: 1 }}
              placeholder="Search ..."
              inputProps={{ "aria-label": "   search" }}
              onChange={setSearchFun}
              value={search}
              // disabled={loading || loadingdis}
            />
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="search"
              style={{
                backgroundColor: "#FFAB00",
                borderRadius: "10%",
                color: "#FFF",
              }}
              onClick={setSearchFun}
              // disabled={loading || loadingdis}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
          <Tooltip title="Add user">
            <IconButton
              type="submit"
              sx={{ p: "12px" }}
              aria-label="search"
              style={{
                backgroundColor: "#00A1F1",
                borderRadius: "10%",
              }}
              onClick={addUserFun}
              disabled={loading || loadingdis}
            >
              <img src={addicon} style={{ color: "#FFFFFF" }} />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {loadingdis && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <CustomLoader loading={loadingdis} />
          </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
      )}
      {console.log("load", data && data.length)}

      <div
        // className="scr-activity"
        ref={scrollRef}
        onScroll={onScrollHandler}
        className={`scr-activity list-box`}
        name="homeListScroll"
        id="homeListScroll"
        style={{ marginTop: "10px" }}
      >
        {data &&
          // stableSort(data, getSorting("asc", "displayName")).map((r, i) => {
          data.map((r, i) => {
            const {
              displayName = "",
              id = "",
              userPrincipalName = "",
              accountEnabled = "",
              officeLocation = "N/A",
              jobTitle = "N/A",
              department = "",
            } = r;
            return (
              <Grid
                container
                spacing={1}
                style={{
                  backgroundColor: "white",
                  marginTop: "15px",
                  padding: "6px 20px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                data-id={id}
                key={id}
              >
                <Grid
                  item
                  xs={12}
                  md={8}
                  sx={{ display: "content", pl: "0 !important" }}
                >
                  <Stack direction="row" spacing={2}>
                    <Typography
                      style={{
                        marginTop: "12px",
                        fontSize: "20px",
                        fontWeight: "500",
                        lineHeight: "20px",
                      }}
                      data-id={id}
                      disabled={adduser}
                      onClick={handleViewUser}
                      data-flag={true}
                    >
                      {displayName || "N/A"}
                    </Typography>

                    {/* {[department, officeLocation, jobTitle].map(
                      (rol, index) => {
                        return (
                          <Box
                            key={index}
                            component="span"
                            className="resource-box"
                          >
                            <Typography
                              style={{
                                margin: "5px",
                                fontSize: "10px",
                                fontWeight: "400",
                                lineHeight: "20px",
                              }}
                      data-id={id}
                      data-flag={true}
                      disabled={adduser}

                      onClick={handleViewUser}
                              
                            >
                              {rol || ""}
                            </Typography>
                          </Box>
                        );
                      }
                    )} */}
                  </Stack>

                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: "500",
                      wordBreak: "break-all",
                    }}
                    data-id={id}
                    disabled={adduser}
                    onClick={handleViewUser}
                    data-flag={true}
                  >
                    {userPrincipalName || "N/A"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={1}
                  style={{
                    alignContent: "flex-end",
                    pr: "15px",
                    marginTop: "15px",
                  }}
                ></Grid>
                <Grid
                  item
                  xs={12}
                  md={3}
                  style={{
                    alignContent: "flex-end",
                    pr: "15px",
                    marginTop: "15px",
                  }}
                >
                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                    {accountEnabled == true ? (
                      <Box
                        className="user-enable-box"
                        data-enableid={id}
                        data-userprinname={userPrincipalName}
                        onClick={handleClickOpen}
                      >
                        <Stack direction="row" justifyContent="center">
                          <IconButton sx={{ padding: "5px" }}>
                            <img src={oval}></img>
                          </IconButton>
                          <Typography
                            style={{
                              fontWeight: "700",
                              color: "#FFF",
                              fontSize: "12px",
                              margin: "5px",
                              pr: "5px !important",
                            }}
                          >
                            Enabled
                          </Typography>
                        </Stack>
                      </Box>
                    ) : (
                      <Box
                        className="user-disable-box"
                        data-enableid={id}
                        data-userprinname={userPrincipalName}
                        onClick={handleEnableUSer}
                      >
                        <Stack direction="row" justifyContent="center">
                          <IconButton sx={{ padding: "5px" }}>
                            <img src={oval}></img>
                          </IconButton>
                          <Typography
                            style={{
                              fontWeight: "700",
                              color: "#FFF",
                              fontSize: "12px",
                              margin: "5px",
                              pr: "5px !important",
                            }}
                          >
                            Disabled
                          </Typography>
                        </Stack>
                      </Box>
                    )}
                    <IconButton
                      aria-label="delete"
                      style={{
                        backgroundColor: "none",
                        borderRadius: "10%",
                      }}
                      id={id}
                      key={id}
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      size="small"
                      data-id={id}
                      onClick={setShownFun}
                    >
                      <img
                        src={MoreIcon}
                        style={{ width: "16px", height: "17px" }}
                      />
                    </IconButton>
                  </Stack>
                </Grid>
                <StyledMenu
                  className="userl-popup"
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={handleViewUser}
                    disableRipple
                    data-id={deleteid}
                    data-flag={false}
                  >
                    <img style={{ paddingRight: "15px" }} src={editIcon} />
                    Edit
                  </MenuItem>
                  <MenuItem onClick={handleDelete} disableRipple>
                    <img style={{ paddingRight: "15px" }} src={delIcon} />
                    Delete
                  </MenuItem>
                </StyledMenu>
              </Grid>
            );
          })}
        {loading ? (
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}></Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{ textAlign: "center", margin: "40px" }}
            >
              <CustomLoader loading={loading} />
            </Grid>
            <Grid item xs={12} md={4}></Grid>
          </Grid>
        ) : !loading && data && data.length == 0 ? (
          <Grid
            item
            xs={12}
            md={12}
            style={{ textAlign: "center", margin: "40px" }}
          >
            <Typography>No matching records found.</Typography>
          </Grid>
        ) : (
          <></>
        )}
        {!loading && data.length === toFirst && (
          <Typography sx={{ textAlign: "center", margin: "5px" }}>
            No more records
          </Typography>
        )}
      </div>

      {/* {!loading && data && data.length > 0 ? (
        <Grid container>
          <Grid item xs={6}></Grid>
          <Grid item xs={6} sx={{ padding: "15px" }}>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 25]}
              //  colSpan={3}
              count={toFirst}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Grid>
        </Grid>
      ) : (
        <></>
      )} */}
      {/* <Dialog
        open={openDel}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to Delete the user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDel}>No</Button>
          <Button onClick={handleCloseDelyes} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog> */}
      {adduser && <AddNewUserPopup open={adduser} handleClose={addUserFun} />}
      {viewOpen && (
        <ViewUserDetailsPopup
          open={viewOpen}
          handleClose={hc}
          userid={uid}
          userData={userData}
          getUsersList={getUsersList}
          flag={editFlag}
        />
      )}
      {openPop && (
        <UserDisableConfirmPopup
          open={openPop}
          handleClose={handleCloseConfirm}
          userid={enableid}
          userPrinName={userPrinName}
        />
      )}
      {openfil && (
        <UsersFilter
          open={openfil}
          handleClose={openFilter}
          setFilter={setFilter}
          filter={filter}
          setSearch={setSearch}
          setPageUrl={setPageUrl}
          setData={setData}
        />
      )}
      {openDel && (
        <DeleteUser
          open={openDel}
          handleClose={deleteclose}
          id={deleteid}
          deletecloseSuccess={deletecloseSuccess}
        />
      )}
    </>
  );
}
