import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import filicon from "../images/filter-new.png";
import SearchIcon from "@mui/icons-material/Search";
import delIcon from "../images/deleteicon.png";
import {
  DASHBOARD_ACTIVITY_LOG_LIST_URL,
  DELETE_ACTIVITY_LOG_URL,
} from "./common/ApiUrl";
import { CustomMessage, authHeader, getSideFlag } from "./common/mainfunctions";
import moment from "moment";
import API from "./common/API";
import CustomLoader from "./common/CustomLoader";
import Navbar from "./layout/Navbar";
import { useSnackbar } from "notistack";
import ActivityLogFilter from "./ActivityLogFilter";
import clrfilter from "../images/clearfilter.svg";
import { getSorting, stableSort } from "./common/function";
import { useNavigate } from "react-router-dom";
const initialValues = {
  activityType: "",
  content: "",
  userRole: "",
};
export default function DashboardActivityLogs() {
  const [loading, setLoading] = useState(false);
  const [logList, setLogList] = useState();
  const [searchFilter, setSearch] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [openfil, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(initialValues);
  const navigation = useNavigate();
  const getLogList = async () => {
    setLoading(true);
    const { activityType, content, userRole } = filter;
    const config = {
      method: "GET",
      url: DASHBOARD_ACTIVITY_LOG_LIST_URL,
      headers: authHeader(),
      params: {
        search: searchFilter,
        offset: 0,
        limit: 500,
        activityTypeFilter: activityType ? activityType : null,
        contentFilter: content ? content : null,
        userRoleFilter: userRole ? userRole : null,
      },
    };
    try {
      const response = await API(config);
      const { status, data } = response.data;
      if (status == "Success") {
        setLogList(data.logList);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getLogList();
  }, [searchFilter, filter]);
  const setSearchFun = (e) => {
    setSearch(e.target.value);
  };
  const deleteActivity = async (e) => {
    const { id } = e.currentTarget.dataset;
    const config = {
      method: "DELETE",
      url: DELETE_ACTIVITY_LOG_URL,
      headers: authHeader(),
      data: {
        logId: parseInt(id),
      },
    };
    try {
      const response = await API(config);
      const { message, status } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        getLogList();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const openFilter = () => {
    setOpenFilter(!openfil);
  };
  const handleClose = () => {
    setOpenFilter(!openfil);
    setFilter(initialValues);
  };
  const clearFilter = () => {
    setFilter(initialValues);
  };
  const isAnyFilterApplied = Object.values(filter).some(
    (value) => value !== ""
  );
  useEffect(() => {
    const tok = authHeader();
    if (!tok) {
      navigation("/signin");
    }
  }, []);
  return (
    <>
      {getSideFlag() == "true" ? <></> : <Navbar name="Activity Logs" />}
      <Grid
        sx={{ mt: getSideFlag() === "true" ? 1 : 10, ml: "-15px" }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 2 }}
      >
        <Grid item xs={12} md={3}>
          <Typography className="log-header">
            Activities
            <Tooltip title="Filter">
              <IconButton aria-label="delete" size="small" onClick={openFilter}>
                <img src={filicon} />
              </IconButton>
            </Tooltip>
            {isAnyFilterApplied && (
              <Tooltip title="Clear Filter">
                <IconButton
                  aria-label="delete"
                  sx={{ padding: "10px" }}
                  size="small"
                  onClick={clearFilter}
                >
                  <img src={clrfilter} />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        </Grid>

        <Grid item xs={12} md={9} lg={9} sx={{ display: "flex" }}>
          <Paper
            //   component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "-webkit-fill-available",
              marginTop: "auto",
              marginBottom: "auto",
              // marginRight: "20px",
              height: "50px",
              boxShadow: "none !important",
            }}
          >
            <InputBase
              sx={{ flex: 1 }}
              placeholder="Search ..."
              inputProps={{ "aria-label": "   search" }}
              onChange={setSearchFun}
              value={searchFilter}
            />
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="search"
              style={{
                backgroundColor: "#FFAB00",
                borderRadius: "10%",
                color: "#FFF",
              }}
              onClick={setSearchFun}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Grid>
        {!loading && logList && logList.length == 0 ? (
          <Grid item xs={12} md={12} lg={12} sx={{ display: "flex" }}>
            <Grid item xs={5} md={5}></Grid>
            <Grid item xs={2} md={2}>
              <Box sx={{ textAlign: "center", mt: "15px" }}>
                <Typography>No matching records found.</Typography>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={12} lg={12} sx={{ display: "flex" }}>
              <Grid item xs={5} md={5}></Grid>
              <Grid item xs={2} md={2}>
                <CustomLoader loading={loading} />
              </Grid>
            </Grid>
          </>
        )}
        <Grid item xs={12} md={12} lg={12}>
          <div style={{ paddingRight: "15px" }} className="scr-activity">
            {!loading &&
              logList &&
              stableSort(logList, getSorting("desc", "createdDate")).map(
                (r, i) => {
                  const {
                    content = "",
                    createdDate = "",
                    companyId = "",
                    activityType = "",
                    createdBy = "",
                    userRole,
                    id,
                  } = r;

                  return (
                    <Grid
                      container
                      spacing={2}
                      style={{
                        backgroundColor: "white",
                        marginTop: "15px",
                        // marginLeft: "20px",
                        borderRadius: "5px",
                        paddingRight: "10px !important",
                      }}
                    >
                      <Grid item xs={12} md={6}>
                        <Stack
                          direction="row"
                          spacing={{ xs: 1, sm: 1, md: 1 }}
                          flexWrap="wrap"
                        >
                          <Typography
                            sx={{ mt: "0px" }}
                            variant="h6"
                            className="log-user"
                          >
                            {content.split(/ has?| have?/)[0]}
                          </Typography>
                          <Typography sx={{ mt: "0px" }} className="log-have">
                            has been
                          </Typography>
                          <Typography
                            sx={{ mt: "0px" }}
                            className={
                              activityType == "Created"
                                ? "log-create"
                                : activityType == "Deleted"
                                ? "log-delete"
                                : activityType == "Updated"
                                ? "log-update"
                                : "log-pending"
                            }
                          >
                            {activityType}
                          </Typography>
                          <Typography
                            sx={{ mt: "0px", mr: "8px !important" }}
                            className="log-have"
                          >
                            by
                          </Typography>
                          <Typography
                            sx={{ mt: "0px", ml: "0px !important" }}
                            className="log-user"
                          >
                            {userRole}
                          </Typography>
                        </Stack>

                        <Typography
                          className="log-datefield"
                          sx={{ mb: "12px" }}
                        >
                          {moment(new Date(createdDate)).format(
                            "dddd, MMMM DD YYYY "
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={4}></Grid>
                      <Grid item xs={2} md={1}>
                        <Box
                          sx={{
                            p: "3px 5px",
                            border: "1px solid #BBB5B5",
                            borderRadius: "8%",
                            textAlign: "center",
                            width: "fit-content",
                            maxWidth: "70px",
                            float: "right",
                            borderRadius: "5px",
                            mb: { xs: "10px", sm: "10px", md: "0px" },
                          }}
                        >
                          <Typography
                            className="acti-ad"
                            style={{ fontSize: "10px", fontWeight: "700" }}
                          >
                            Admin
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={2} md={1}>
                        <Tooltip title="Delete">
                          <IconButton
                            sx={{ mb: { xs: "10px", sm: "10px", md: "0px" } }}
                            aria-label="delete"
                            size="small"
                            onClick={deleteActivity}
                            data-id={id}
                          >
                            <img src={delIcon} />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  );
                }
              )}
          </div>
        </Grid>

        {openfil && (
          <ActivityLogFilter
            open={openfil}
            handleClose={handleClose}
            setFilter={setFilter}
            filter={filter}
            setSearch={setSearch}
            setOpenFilter={setOpenFilter}
          />
        )}
      </Grid>
    </>
  );
}
