import React, { useEffect, useState } from "react";
import untitle from "../images/todashboard.png";
import layerig from "../images/layer-1.svg";
import line2 from "../images/line-2.svg";
import group82 from "../images/group-82.png";
import group821 from "../images/group-82-1.png";
import polygon from "../images/polygon-2.svg";
import mockup from "../images/macbook-air-mockup-1-1.png";
import file1 from "../images/dashboard.png";
import file2 from "../images/org setting.png";
import file3 from "../images/user request.png";
import file4 from "../images/users.png";
import file5 from "../images/new user.png";
import fr4 from "../images/frame-18-4.svg";
import fr1 from "../images/frame-18-1.svg";
import fr2 from "../images/frame-18-2.svg";
import fr3 from "../images/frame-18-3.svg";
import fr5 from "../images/frame-18-5.svg";
import ig3 from "../images/image-3.png";
import ig1 from "../images/image-1.png";
import object from "../images/objects.svg";
import logocontain from "../images/logo-container.svg";
import copyright from "../images/copyright-1.svg";
import down1 from "../images/download-1.png";
import { useNavigate } from "react-router";
import mailicon from "../images/main-icon.png";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import {
  DASHBOARD_ROUTE,
  SIGNIN_ROUTE,
  SIGNUP_ROUTE,
} from "../constants/RoutePath";
import "./style.css"; // Import the new style.css
import {
  authHeader,
  authHeaderchk,
  CustomMessage,
  getSideFlag,
  getTok,
} from "./common/mainfunctions";
import * as microsoftTeams from "@microsoft/teams-js";
import { pages } from "@microsoft/teams-js";

import { useSnackbar } from "notistack";
import { EnergySavingsLeaf } from "@mui/icons-material";
import { Box, Container, Grid, Tab, Tabs } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
export default function LandingPageTeams() {
  const navigate = useNavigate();
  const [idx, setidx] = useState(1);
  const images = [file1, file1, file2, file3, file4, file5];
  const [sideFlag, setSideFlag] = useState(false);
  const [mobFlag, setMobFlag] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (
      /android|iPhone|iPad|iPod/i.test(userAgent) ||
      window.location.href.includes("teams.microsoft.com")
    );
  };
  const isInTeams = () => {
    // This function checks if the app is running inside Microsoft Teams
    return window.self !== window.top;
  };
  useEffect(() => {
    if (isMobile()) {
      setMobFlag(true);
      // CustomMessage(
      //   `I am in mobile`,
      //   "error",
      //   enqueueSnackbar
      // );
      console.log("Running inside Microsoft Teams on Mobile");
      // Handle mobile-specific logic
    }
  }, []);

  
  function getCookie(name) {
    console.log(name, document.cookie);
    const value = `; ${document.cookie}`;
    console.log(value);
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
  }

 
  useEffect(() => {
    // Check if the app is running inside Microsoft Teams
    if (window.location !== window.parent.location) {
      setSideFlag(true);
      localStorage.setItem("SIDE_FLAG", true);
      // microsoftTeams.app.initialize();
  //  CustomMessage(`sdk initialized`, "success", enqueueSnackbar);

    } else {
      // microsoftTeams.app.initialize();

      console.log("App is running outside of Microsoft Teams");
      localStorage.setItem("SIDE_FLAG", false);
      // CustomMessage(`sdk else initialized`, "info", enqueueSnackbar);

    }
  }, []);
  
  // const getStartFun = async () => {
  //   try {
  //     // Initialize the Teams SDK
  //     microsoftTeams.initialize();
  
  //     // Get the current context
  //     microsoftTeams.getContext((context) => {
  //       console.log("Current Context:", context);
  //       // CustomMessage(`context${JSON.stringify(context)}`, "info", enqueueSnackbar);
  
  //       const isMobileClient =
  //         context.hostClientType === "ios" || context.hostClientType === "android";
  
  //       // Check if we are currently on the "About" tab
  //       if (context.entityId === "info") {
  //         console.log("Currently on the About tab. Navigating to the App tab...");
          
  //         // Navigate to the App tab
  //         microsoftTeams.navigateToTab(
  //           {
  //             tabInstanceId: "app", // Match the "App" tab instance ID in your manifest
  //             entityId: "app",      // Match the entityId for the "App" tab
  //           },
  //           (success, error) => {
  //             if (success) {
  //               console.log("Successfully navigated to the App tab.");
  //             } else {
  //               console.error("navigateToTab failed:", error);
  
  //               // Fallback for mobile: Use deep linking if navigateToTab fails
  //               if (isMobileClient) {
  //                 console.log("Fallback: Attempting deep link...");
  //                 const deepLink = `https://teams.microsoft.com/l/entity/7e4c6a26-f33d-4680-b2f7-3a1366cca4c9/app?webUrl=https://hrassist.app/entry`;
  //                 microsoftTeams.executeDeepLink(deepLink, (deepLinkSuccess) => {
  //                   if (deepLinkSuccess) {
  //                     console.log("Deep link succeeded.");
  //                   } else {
  //                     console.error("Deep link failed.");
  //                   }
  //                 });
  //               }
  //             }
  //           }
  //         );
  //       } else {
  //         console.warn("Already on the target tab. No navigation needed.");
  //       }
  //     });
  //   } catch (error) {
  //     console.error("Error in getStartFun:", error);
  //   }
  // };
  
//  works // 
  // const getStartFun = async () => {
  //   const token = getCookie("userToken");
  //   const subdomain = getCookie("userdomain");
  //   const firstTabId = "app";
  
  //   // Fetch Teams context
  //   microsoftTeams.getContext((context) => {
  //     const isMobileClient =
  //       context.hostClientType === "ios" || context.hostClientType === "android";
  
  //     console.log("Context fetched:", context);
  
  //     if (getSideFlag() === "true") {
  //       // Navigate using a deep link
  //       microsoftTeams.executeDeepLink(
  //         "https://teams.microsoft.com/l/entity/7e4c6a26-f33d-4680-b2f7-3a1366cca4c9/app?webUrl=https://hrassist.app/entry"
  //       );
  //     } else if (isMobileClient) {
  //     CustomMessage(`context yes`, "info", enqueueSnackbar);
       
  //       microsoftTeams.pages.navigateToApp({
  //         pageId: "app", // Replace with your tab ID from the manifest
  //         webUrl: "https://hrassist.app/app", // Optional URL if applicable
  //       });
        
      
  //     } else {
  //       // Handle desktop logic
  //       if (token) {
  //         // Redirect to the subdomain dashboard
  //         window.location.href = `https://${subdomain}.hrassist.app/dashboard`;
  //       } else {
  //         let currentDomain = localStorage.getItem("CURRENT");
  //         if (currentDomain) {
  //           window.location.href = `${currentDomain}/dashboard`;
  //         } else {
  //           let hostname = window.location.hostname;
  //           let subdom = hostname.split(".")[0];
  //           if (
  //             hostname !== "devhrassist.app" &&
  //             subdom !== "localhost" &&
  //             subdom !== "hrassist"
  //           ) {
  //             if (authHeader()) {
  //               navigate(DASHBOARD_ROUTE);
  //             } else {
  //               navigate("/signin");
  //             }
  //           } else {
  //             navigate(SIGNUP_ROUTE);
  //           }
  //         }
  //       }
  //     }
  //   });
  // };
  // trying
 

  const getStartFun = async () => {
    const token = getCookie("userToken");
    const subdomain = getCookie("userdomain");
    const firstTabId = "entry"; // Tab identifier for the 'App' tab
    // CustomMessage(`call`, "info", enqueueSnackbar);
  
    try {
      // Check if Teams SDK is available
      if (microsoftTeams) {
        // CustomMessage(`inside teams`, "info", enqueueSnackbar);
  
        // Initialize Teams SDK
        await microsoftTeams.app.initialize();
        console.log("Teams SDK initialized");
        // CustomMessage(`initialized`, "info", enqueueSnackbar);
  
        // Fetch context
        let context;
        try {
          context = await microsoftTeams.app.getContext();
          // context = await microsoftTeams.getContext();
        } catch (error) {
          console.error("Error fetching context:", error);
          // CustomMessage(`Error fetching context: ${error.message}`, "error", enqueueSnackbar);
          return; // Exit if context fetch fails
        }
  
        if (context) {
          console.log("Context fetched:", context);
          // CustomMessage(`context: ${context.page?.id}${context.host?.clientType}${context.app.host?.clientType}`, "info", enqueueSnackbar);
          // CustomMessage(`pages: ${JSON.stringify(context, null, 2)}`, "info", enqueueSnackbar);

          // Check if app is running in a mobile client
          const isMobileClient=
            context.app.host?.clientType === "ios" || context.app.host?.clientType === "android";
          // console.log("Is Mobile Client:", isMobileClient);
          // CustomMessage(`Is Mobile Client:${isMobileClient}`, "info", enqueueSnackbar);
  
          // Navigate to the 'App' tab
          try {
            await microsoftTeams.pages.navigateToApp({  appId: "7e4c6a26-f33d-4680-b2f7-3a1366cca4c9",
              pageId: "entry",});
            // CustomMessage(`Successfully navigated to the 'App' tab.`, "success", enqueueSnackbar);
            console.log("Successfully navigated to the 'App' tab.");
          } catch (err) {
            const tabUrl = "https://hrassist.app/entry";
    // window.location.href = tabUrl;
            // CustomMessage(`Tab navigation failed: ${err.message}${JSON.stringify(err)}`, "error", enqueueSnackbar);
            console.error("Failed to navigate to the 'App' tab:", err);
          }
          
        } else {
          console.error("Context is empty or not fetched.");
          // CustomMessage("Context is empty or not fetched.", "error", enqueueSnackbar);
        }
      } else {
       
    }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      if (token&&!microsoftTeams) {
        // Redirect to the subdomain dashboard
        window.location.href = `https://${subdomain}.hrassist.app/dashboard`;
      } else {
        let currentDomain = localStorage.getItem("CURRENT");
        if (currentDomain) {
          window.location.href = `${currentDomain}/dashboard`;
        } else {
          let hostname = window.location.hostname;
          let subdom = hostname.split(".")[0];
          if (
            hostname !== "devhrassist.app" &&
            subdom !== "localhost" &&
            subdom !== "hrassist"
          ) {
            if (authHeader()) {
              navigate(DASHBOARD_ROUTE);
            } else {
              navigate("/signin");
            }
          } else {
            navigate(SIGNUP_ROUTE);
          }
        }
}
      // CustomMessage(`Unexpected error: ${error.message}`, "error", enqueueSnackbar);
    }
  };
  
  
  useEffect(() => {
    const token = getCookie("userToken");
    const subdomain = getCookie("userdomain");
    console.log(sideFlag, "sideflag");
    if (getSideFlag() == "true" || getSideFlag() == true) {
      console.log("i am mobile or teams");
    } else {
      if (token && token != undefined && isMobile() == false) {
        // Redirect to the subdomain dashboard with the token
        window.location.href = `https://${subdomain}.hrassist.app/dashboard`;
      }
      let t = localStorage.getItem("CURRENT");
      console.log(getTok(), authHeader(), t);

      if (t) {
        let url = `${t}/dashboard`;
        // localStorage.clear();
        // window.open(url, "_self");
      } else {
        let hostname = window.location.hostname;
        let subdom = hostname.split(".")[0]; // Extract subdomain
        console.log("Subdomain:", subdom);
        console.log("Hostname:", hostname);

        if (
          hostname !== "devhrassist.app" &&
          subdom !== "localhost" &&
          subdom !== "hrassist"
        ) {
          console.log("Valid subdomain, navigating...");
          if (authHeader()) {
            navigate(DASHBOARD_ROUTE);
          } else {
            // navigate("/signin");
          }
        }
      }
    }
  }, []);
  const idxfun = (id) => {
    console.log(id, "called");
    setidx(id);
  };
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box className="dashboard-section">
        <img class="top-design" src={group82} />
        <img class="bottom-design" src={group821} />
        <img class="polygon-icon" src={polygon} />
        <Box sx={{ position: "relative", zIndex: 1 }}>
          <p style={{ color: "#FFF", fontSize: "x-large" }}>
            👋 Welcome to HR Assist
          </p>
          <p className="main-heading">
            Streamline Employee Onboarding and <br />
            Offboarding with Ease
          </p>
          <img class="line-img" src={line2} />
          <p className="header-subtext">
            HR Assist empowers your HR team to manage user accounts, assign M365
            licenses, and update
            <br />
            employee data—all without IT involvement.
          </p>
          <button onClick={getStartFun} className="btns">
            Get Started
          </button>
          <img
            style={{ marginBottom: "-13px" }}
            className="dashboard-img"
            src={untitle}
          />
        </Box>
      </Box>
      <Box className="about-section">
        <Container>
          <Grid container alignItems="center" spacing={{ xs: 2, sm: 3, md: 5 }}>
            <Grid item xs={12} sm={12} md={6}>
              <Box className="about-ellipse">
                <img class="macbook-air" src={mockup} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Box className="about-bg">ABOUT US</Box>
              <p className="ef-title">Efficient User Management</p>
              <p className="hr-text">
                HR Assist is a self-service tool designed to streamline the
                onboarding, updating, and offboarding of employees, providing HR
                teams with a quick and efficient way to manage user accounts.
                Integrated with Microsoft Entra ID, Pax8, and Intune, HR Assist
                ensures seamless user management and enhanced operational
                efficiency.
              </p>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="feature-section">
        <Box sx={{ textAlign: "center" }}>
          <Box sx={{ margin: "auto" }} className="about-bg">
            Features
          </Box>
          <p className="ef-title">Key Features of HR Assist</p>
          <p className="hr-text">
            Explore how HR Assist simplifies HR tasks with seamless user
            management, automated license assignments,
            <br />
            and integrated workflows. Experience efficient, secure HR operations
            with our powerful tool.
          </p>
        </Box>
        <Container>
          <Grid container direction="row" sx={{ mt: 3 }} spacing={3}>
            <Grid item xs={12} md={4}>
              <Box className="ft-icon-box">
                <img src={fr4} />
                <p className="ft-box-title">Effortless User Management</p>
                <p className="ft-box-txt">
                  Easily create, update, and offboard employee accounts with
                  just a few clicks, streamlining your HR processes.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="ft-icon-box">
                <img class="frame-10" src={fr1} />
                <p className="ft-box-title">Seamless License Assignment</p>
                <p className="ft-box-txt">
                  Quickly assign and manage Microsoft 365 licenses to ensure all
                  employees have the tools they need from day one.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="ft-icon-box">
                <img src={fr2} />
                <p className="ft-box-title">Streamlined Distribution Lists</p>
                <p className="ft-box-txt">
                  Organize and manage distribution lists to facilitate effective
                  communication and collaboration within your organization.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="ft-icon-box">
                <img src={fr3} />
                <p className="ft-box-title">Approval-Based Workflows</p>
                <p className="ft-box-txt">
                  Implement secure, approval-based workflows to ensure
                  compliance and maintain control over HR processes.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="ft-icon-box">
                <img src={fr4} />
                <p className="ft-box-title">
                  Integrated with Microsoft Entra ID
                </p>
                <p className="ft-box-txt">
                  Enjoy seamless integration with your Microsoft Entra ID,
                  enhancing security and user management without creating
                  external accounts.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="ft-icon-box">
                <img src={fr5} />
                <p className="ft-box-title">Enhanced Security</p>
                <p className="ft-box-txt">
                  Leverage integrations with Microsoft Entra ID, Pax8, and
                  Intune for secure, efficient management of users and devices.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="screenshot-section">
        <Container>
          <Box sx={{ margin: "auto" }} className="about-bg">
            SCREENSHOTS
          </Box>
          <p className="ef-title">Explore HR Assist in Action</p>
          <p className="hr-text">
            Discover how HR Assist streamlines HR tasks with intuitive user
            management and seamless integrations. <br />
            Our screenshots highlight key features that enhance efficiency and
            simplify operations.
          </p>
          <Box>
            <TabContext value={value}>
              <Box
                sx={{
                  margin: "auto",
                  width: "100%",
                  bgcolor: "background.paper",
                  mt: 2,
                  maxWidth: "810px",
                  padding: "4px",
                }}
              >
                <TabList value={value} onChange={handleChange} centered>
                  <Tab label="Dashboard Overview" value="1" />
                  <Tab label="License Management" value="2" />
                  <Tab label="Request Actions" value="3" />
                  <Tab label="User Management" value="4" />
                  <Tab label="Request Submission" value="5" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <img src={file1} />
              </TabPanel>
              <TabPanel value="2">
                <img src={file2} />
              </TabPanel>
              <TabPanel value="3">
                <img src={file3} />
              </TabPanel>
              <TabPanel value="4">
                <img src={file4} />
              </TabPanel>
              <TabPanel value="5">
                <img src={file5} />
              </TabPanel>
            </TabContext>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          background: "#fff",
          height: { xs: "250px", sm: "250px", md: "400px" },
          mt: "-270px",
          display: { xs: "none", sm: "none", md: "block" },
        }}
      ></Box>
      <Box className="integration-section">
        <Container>
          <Box sx={{ textAlign: "center" }}>
            <Box sx={{ margin: "auto" }} className="about-bg">
              INTEGRATION
            </Box>
            <p className="ef-title">Integration Highlights</p>
            <p className="hr-text">
              HR Assist integrates with your Microsoft Entra ID, Pax8, and
              Intune for efficient user and software management. <br />
              Enjoy a unified platform for streamlined operations and effective
              device management.
            </p>
          </Box>
          <Grid sx={{ mt: 2 }} container spacing={2} direction="row">
            <Grid item xs={12} md={4}>
              <Box className="int-box">
                <img src={ig3} />
                <p className="int-title">Microsoft Entra ID</p>
                <p className="int-text">
                  Leverages Microsoft Entra ID for efficient identity and access
                  management.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="int-box">
                <img src={ig1} />
                <p className="int-title">Pax8 Integration</p>
                <p className="int-text">
                  Utilizes Pax8 for streamlined software and services
                  management.
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box className="int-box">
                <img class="img-2" src={down1} />
                <p className="int-title">Intune Management</p>
                <p className="int-text">
                  Integrates with Intune to manage and secure devices, ensuring
                  compliance and protection.
                </p>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box class="footer">
        <p className="ft-heading">Reach Out to HR Assist</p>
        <p className="ft-txt">
          Got a question or need assistance? Our team is ready to help. Reach
          out to us through the following contact details:
        </p>
        <img class="img-txt" src={object} alt="" />
        <div className="support-mail">
          <MarkunreadOutlinedIcon color="#fff" />
          <p className="ft-txt">help@intelligis.com</p>
        </div>
      </Box>
    </>
  );
}
