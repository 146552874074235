export const SIGNIN_ROUTE = "/signin:id";

// export const SIGNIN_ROUTE="/:id/signin"
export const INITIAL_ROUTE = "/";
export const DASHBOARD_ROUTE = "/dashboard";
export const ACCOUNT_SETTING_ROUTE = "/account";
export const ORGANIZATION_SETTING_ROUTE = "/organization";
export const APPROVAL_REQUESTS = "/approverequests";
export const USERS_ROUTE = "/users";
export const REPORTS_ROUTE = "/reports";
export const ORGANIZATION_EDIT_ROUTE = "/organization-edit";
export const ORGANIZATION_ADD_ROUTE = "/addorganization";
// export const ORGANIZATION_ADD_ROUTE2="/addorganizationtwo"
export const ORGANIZATION_CREATE_WORK_FLOW = "/addworkflow";
export const SIGNUP_ROUTE = "/signup";
export const SYSTEM_USERS_ROUTE = "/system-users";
export const ORGANIZATION_DETAILS_ROUTE = "/organization-details";
export const GROUP_LIST_ROUTE = "/groups";
export const ACTIVITY_LOGS_ROUTE = "/activitylogs";
export const ACCOUNT_SETTINGS_EDIT_ROUTE = "/account-edit";
export const REDIRECT_ROUTE = "/redirect";
export const DEVICE_ROUTE = "/device";
// export const SIGN_UP_DOMAIN_ROUTE="/signup-domain"
// terms
export const TERMS_ROUTE = "/terms";
export const PRIVACY_POLICY = "/policy";
export const SUPPORT_ROUTE = "/support";
export const MAILBOX_ROUTE = "/mailbox";
export const APPROVE_LIST_ROUTE = "/approvalrequests";
export const ENTRY_PATH = "/entry";
export const TEAMS_AUTH_PATH = "/tsauth";
