import React, { useEffect, useState } from "react";
import {
  BOT_PUBLISH_URL,
  CODE_AUTH_FOR_USER_URL,
  DEVICE_NEW_PERMISSION_URL,
  FIRST_ALERT_FLAG_URL,
  PERMISSIONN_FLAG_UPDATE_URL,
  PERMISSION_URL,
} from "../../common/ApiUrl";
import { CustomMessage, authHeader } from "../../common/mainfunctions";
import API from "../../common/API";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  Typography,
  alpha,
} from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import botimg from "../../../images/color80.png";
import { useDispatch } from "react-redux";
import { getUserDetails } from "../../../redux/action";
import CustomLoader from "../../common/CustomLoader";
import CustomLoaderSmall from "../../common/CustomLoaderSmall";
import * as microsoftTeams from "@microsoft/teams-js";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(10),
  },
  "& .MuiInputBase-input": {
    borderRadius: "0px",
    position: "relative",
    backgroundColor: "#F8F8F8",
    //border: "2px solid #CFCFCF",
    fontSize: 18,
    width: "100%",
    padding: "18px 20px",
    color: "#343434",
    marginTop: "15px !important",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
const initialValues = {
  appObjectId: "",
};
export default function BotIntegration(props) {
  let cliendid = process.env.REACT_APP_CLIENT_ID;
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [authEmail, setEmail] = useState();
  const [authFlag, setAuthFlag] = useState(true);
  const [code, setCode] = useState();
  const { open, handleClose, cd } = props;
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    // appObjectId: Yup.string().trim().required("Object Id is required"),
  });
  const [mobFlag, setMobFlag] = useState(false);
  const [sideFlag, setSideFlag] = useState(false);

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (
      /android|iPhone|iPad|iPod/i.test(userAgent) ||
      window.location.href.includes("teams.microsoft.com")
    );
  };
  useEffect(() => {
    if (isMobile()) {
      setMobFlag(true);
      console.log("Running inside Microsoft Teams on Mobile");
      // Handle mobile-specific logic
    }
  }, []);

  useEffect(() => {
    // Check if the app is running inside Microsoft Teams
    if (window.location !== window.parent.location) {
      setSideFlag(true);
      localStorage.setItem("SIDE_FLAG", true);
      microsoftTeams.initialize();
    } else {
      console.log("App is running outside of Microsoft Teams");
      localStorage.setItem("SIDE_FLAG", false);
      setSideFlag(false);
    }
  }, []);
  const isInTeams = () => {
    return window.self !== window.top;
  };
  const handleFirstTok = async () => {
    if (isInTeams()) {
      window.open(
        `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                      response_type=id_token%20code
                      &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send%20Application.Read.All
                      &client_id=${cliendid}
                      &redirect_uri=${redirecturl}
                      &state=12345
                      &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                      &client_info=1
                      &x-client-SKU=MSAL.JS
                      &x-client-Ver=1.4.18
                      &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                      &response_mode=fragment`,
        "_blank"
      );
      // Initialize Microsoft Teams SDK only if inside Teams
      // microsoftTeams.initialize((initErr) => {
      //   if (initErr) {
      //     console.error("Teams initialization error:", initErr);
      //     return;
      //   }
      //   console.log("Teams SDK initialized");

      //   // Now use Teams authentication flow
      //   microsoftTeams.authentication.authenticate({
      //     url: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
      //                   response_type=id_token%20code
      //                   &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send%20Application.Read.All
      //                   &client_id=${cliendid}
      //                   &redirect_uri=${redirecturl}
      //                   &state=12345
      //                   &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
      //                   &client_info=1
      //                   &x-client-SKU=MSAL.JS
      //                   &x-client-Ver=1.4.18
      //                   &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
      //                   &response_mode=fragment`,
      //     width: 500,
      //     height: 600,
      //     successCallback: (result) => {
      //       console.log("Authentication successful:", result);
      //       // Handle the result (e.g., token)
      //     },
      //     failureCallback: (reason) => {
      //       console.error("Authentication failed:", reason);
      //     },
      //   });

      // });
    } else if (mobFlag === true) {
      // CustomMessage('yes im', "success", enqueueSnackbar);

      // Mobile flow: redirect-based authentication
      window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                        response_type=id_token%20code
                        &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send%20Application.Read.All
                        &client_id=${cliendid}
                        &redirect_uri=${redirecturl}
                        &state=12345
                        &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                        &client_info=1
                        &x-client-SKU=MSAL.JS
                        &x-client-Ver=1.4.18
                        &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                        &response_mode=fragment`;
    } else {
      const popup = window.open("", "_blank", "width=500,height=600");
      window.addEventListener("message", (event) => {});
      popup.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
                        response_type=id_token%20code
                        &scope=openid%20profile%20user.read%20offline_access%20ChatMessage.Send%20Application.Read.All
                        &client_id=${cliendid}
                        &redirect_uri=${redirecturl}
                        &state=12345
                        &nonce=b3d7352f-1915-45a7-9460-946a06e3341e
                        &client_info=1
                        &x-client-SKU=MSAL.JS
                        &x-client-Ver=1.4.18
                        &client-request-id=c7a53cff-9437-419b-b76d-d741fb322c51
                        &response_mode=fragment`;
    }
  };

  const handleCloseBef = async () => {
    // CustomMessage(`$cll`, "success", enqueueSnackbar);

    setLoading(true);
    const config = {
      method: "POST",
      url: BOT_PUBLISH_URL,
      headers: authHeader(),
    };
    try {
      const res = await API(config);
      const { status, data, message } = res.data;
      console.log("mes", message);
      if (status == "Success") {
        // CustomMessage(`$botcll${message}`, "success", enqueueSnackbar);

        CustomMessage(message, "success", enqueueSnackbar);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const handleMessage = (event) => {
      const { data } = event;
      if (data && data.type === "tokenMessage") {
        console.log("data", data);
        // Assuming that the message contains the token
        // setCode(data.token.split("::")[1]);
        const receivedToken = data.token.split("::")[0];
        const base64Url = receivedToken.split(".")[1];
        const cd = data.token.split("::")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const decodedData = JSON.parse(atob(base64));
        const userDisplayName = decodedData.preferred_username;
        const tid = decodedData.tid;
        setEmail(userDisplayName);
        setAuthFlag(false);
        setCode(cd);

        event.source.postMessage({ type: "tokenReceived" }, event.origin);
      }
      if (data && data.type === "code") {
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);
  // CustomMessage(`bot294${code}`, "error", enqueueSnackbar);

  const handleDeleFun = async () => {
    const options = {
      method: "GET",
      headers: authHeader(),
      url: CODE_AUTH_FOR_USER_URL,
      params: {
        tokenCode: code ? code : cd,
      },
    };
    try {
      const resp = await API(options);
      const { status, message } = resp.data;
      if (status == "Success") {
        // CustomMessage(message, "error", enqueueSnackbar);
        handleCloseBef();
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (code || cd) {
      handleDeleFun();
      console.log('code',code)
      console.log('code1',cd)
    }
  }, [code, cd]);
  const {
    handleSubmit,
    handleChange,
    setValues,
    values,
    errors,
    setErrors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // permissionFun(values);
    },
  });

  const closeFun = () => {};
  return (
    <>
      <div className="mar-top-azure">
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          disableBackdropClick
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          ></BootstrapDialogTitle>
          <DialogContent className="mar-top-azure">
            <Box style={{ textAlign: "center" }}>
              <img style={{ height: "50px", width: "auto" }} src={botimg} />
            </Box>
            <Typography
              style={{
                fontWeight: "700",
                fontSize: "16px",
                textAlign: "center",
              }}
            >
              Confirmation
            </Typography>
            <form>
              <Typography
                style={{
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >
                Please ensure you have either the Global Administrator role or
                the Teams Service Administrator role in your Entra ID to install
                this bot app in your organization's app catalog
              </Typography>

              {/* <div style={{ textAlign: "right" }}> */}
              <Box style={{ textAlign: "right" }}>
                <Button className="login-btn-apr" onClick={handleFirstTok}>
                  <CustomLoaderSmall loading={loading} />
                  Yes
                </Button>
              </Box>
              {/* </div> */}
            </form>
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}
