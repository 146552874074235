import React, { useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { useSnackbar } from "notistack";
import { CustomMessage } from "./common/mainfunctions";
// const isInTeams = () => {
//   return window.self !== window.top;
// };
function toQueryString(params) {
  return Object.entries(params)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
}
export default function TeamsAuth() {
  const { enqueueSnackbar } = useSnackbar();

  let cliendid = process.env.REACT_APP_CLIENT_ID;
  let redirecturl = process.env.REACT_APP_REDIRECT_URI;
  const loginCallFun = () => {
    // if (isInTeams()) {
    // Initialize Microsoft Teams SDK only if inside Teams
    microsoftTeams.initialize((initErr) => {
      if (initErr) {
        console.error("Teams initialization error:", initErr);

        return;
      }
      microsoftTeams.app.getContext().then((context) => {
        // Generate random state string and store it, so we can verify it in the callback
        let state = "12345"; // _guid() is a helper function in the sample
        localStorage.setItem("simple.state", state);
        localStorage.removeItem("simple.error");

        // Go to the Azure AD authorization endpoint
        let queryParams = {
          client_id: cliendid,
          response_type: "id_token code",
          response_mode: "fragment",
          scope: "openid profile user.read offline_access ChatMessage.Send",
          redirect_uri: window.location.origin + "/redirect",
          nonce: "b3d7352f-1915-45a7-9460-946a06e3341e",
          prompt: "select_account",
          state: state,
          // The context object is populated by Teams; the loginHint attribute
          // is used as hinting information
          login_hint: context.user.loginHint,
        };

        let authorizeEndpoint = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${toQueryString(
          queryParams
        )}`;

        window.location.assign(authorizeEndpoint);
      });
    });
    // }
  };
  useEffect(() => {
    loginCallFun();
  }, []);

  return (
    <>
      <p>Loading ...</p>
    </>
  );
}
