import { Box, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import React from "react";
import CustomLoader from "../common/CustomLoader";
export default function ReportDisabledUsersList(props) {
  const { disabledUsersList, loading } = props;
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
      {loading ? (
        <>
          <Grid
            item
            xs={12}
            md={12}
            style={{ textAlign: "center", margin: "20px" }}
          >
            <CustomLoader loading={loading} />
          </Grid>
        </>
      ) : disabledUsersList && disabledUsersList.length == 0 ? (
        <Grid
          item
          xs={12}
          md={12}
          style={{ textAlign: "center", margin: "20px" }}
        >
          <Typography>No matching records found.</Typography>
        </Grid>
      ) : (
        <></>
      )}
      <div className="scr-activity reports">
        <Grid item xs={12} md={12} style={{ marginTop: "10px" }}>
          {disabledUsersList &&
            disabledUsersList.map((r, i) => {
              console.log(r.formattedDate)
             console.log(formatDate(r.extensionAttribute3))

             console.log(moment(new Date(r.formattedDate)).format(
                "MMMM DD, YYYY "
              ))
              return (
                <Box
                  className="bottom-box line8"
                  key={i}
                  style={{ marginBottom: "10px" }}
                >
                  <Box className="line"></Box>
                  <Typography variant="h4" className="report-title">
                    {/* {r.DisplayName} */}
                    {/* prod */}
                    {r.displayName}
                  </Typography>
                  <Typography className="report-content">
                    {/* {r.UserPrincipalName}| added on{" "}
                    {moment(new Date(r.CreatedDateTime)).format(
                      "MMMM DD, YYYY "
                    )} */}
                    {/* prod */}
                    {r.userPrincipalName}| disabled on{" "}
                    {formatDate(r.extensionAttribute3)}
                  </Typography>
                </Box>
              );
            })}
        </Grid>
      </div>
    </Grid>
  );
}
