import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import filicon from "../../images/filter-new.png";
import SearchIcon from "@mui/icons-material/Search";
import addicon from "../../images/addicon.png";
import delIcon from "../../images/deleteicon.png";
import editIcon from "../../images/editIcon.png";
import styled from "@emotion/styled";
import {
  DELETE_USER_FROM_AZURE_URL,
  GET_USER_BY_ID,
  SYSTEM_USER_LIST,
  USER_SYS_DELETE_URL,
} from "../common/ApiUrl";
import { CustomMessage, authHeader } from "../common/mainfunctions";
import API from "../common/API";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import {
  getAZUserList,
  getDepartmentList,
  getGroupList,
  getHardCodeRoleList,
  getJobTitleList,
  getLicenceList,
  getOfficeList,
  getRoleList,
  getSystemLicenceList,
} from "../../redux/action";
import Switch from "@mui/material/Switch";
import CustomLoader from "../common/CustomLoader";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddNewSystemUser from "./AddNewSystemUSer";
import SystemUserFilter from "./SystemUserFilter";
import clrfilter from "../../images/clearfilter.svg";
import { debounce } from "underscore";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#7cbb00",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const initialValues = {
  email: "",
  firstName: "",
};
export default function SystemUserList() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [search, setSearch] = useState("");
  const [adduser, setAddUSer] = useState(false);
  const [viewOpen, setView] = useState(false);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(true);
  const [openPop, setOpenPop] = React.useState(false);
  const [enableid, setId] = useState();
  const [openfil, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(initialValues);
  const handleClickOpen = (e) => {
    const id = e.currentTarget.dataset.enableid;
    const status = e.currentTarget.dataset.status;
    setId(id);
    setOpenPop(true);
  };

  const handleClosePop = () => {
    setOpenPop(false);
  };
  const handleClosePopyes = () => {
    setOpenPop(false);
    handleEnable();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const setSearchFun = (e) => {
    setSearch(e.target.value);
  };
  const getUsersList = async (search, filter) => {
    setLoading(true);
    const { email, firstName } = filter || [];
    const options = {
      method: "GET",
      url: SYSTEM_USER_LIST,
      headers: authHeader(),
      params: {
        filter: search ? search : null,
        emailFilter: email ? email : null,
        firstNameFilter: firstName ? firstName : null,
      },
    };
    try {
      var apiResponse = await API(options);
      const { status, data } = apiResponse.data;
      if (status === "Success") {
        setData(data);
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
        CustomMessage(message, "error", enqueueSnackbar);
      }
    } finally {
      setLoading(false);
    }
  };
  const setSearchFuncall = () => {
    delaySearch(search);
  };
  const delaySearch = useRef(
    debounce((search, filter) => getUsersList(search, filter), 500)
  ).current;
  useEffect(() => {
    delaySearch(search, filter);
  }, [search, filter]);
  // useEffect(() => {
  //   getUsersList();
  // }, [filter]);
  useEffect(() => {
    dispatch(getRoleList());
    dispatch(getHardCodeRoleList());
    // dispatch(getAZUserList());
  }, []);
  const addUserFun = () => {
    setAddUSer(!adduser);
  };
  const successcall = () => {
    setAddUSer(!adduser);
    getUsersList();
  };
  const getUserDetails = async (id) => {
    const options = {
      method: "GET",
      url: GET_USER_BY_ID + "/" + id,
      headers: authHeader(),
    };
    try {
      var apiResponse = await API(options);
      const { status, data } = apiResponse.data;
      if (status === "Success") {
        setView(!viewOpen);
        setAddUSer(false);
      }
    } catch (error) {
      if (error.response) {
        const { message } = error.response.data;
      }
    }
  };
  const handleViewUser = async (e) => {
    getUserDetails(e.currentTarget.dataset.id);
  };

  const handleDelete = async (e) => {
    const id = e.currentTarget.dataset.id;
    const config = {
      method: "DELETE",
      url: DELETE_USER_FROM_AZURE_URL,
      headers: authHeader(),
      data: {
        userId: id,
      },
    };
    try {
      const response = await API(config);
      const { data, message, status } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        getUsersList();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnable = async (e) => {
    const config = {
      method: "DELETE",
      url: USER_SYS_DELETE_URL,
      headers: authHeader(),
      data: {
        systemUserId: parseInt(enableid),
      },
    };
    try {
      const response = await API(config);
      const { data, message, status } = response.data;
      if (status == "Success") {
        CustomMessage(message, "success", enqueueSnackbar);
        getUsersList();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const openFilter = () => {
    setOpenFilter(!openfil);
    setSearch("");
  };
  const clearFilter = () => {
    setFilter(initialValues);
  };
  const isAnyFilterApplied = Object.values(filter).some(
    (value) => value !== ""
  );
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Grid
            sx={{ mt: 1.5, pl: 2 }}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <Typography
                className="acti-log"
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                  lineHeight: "50px",
                }}
              >
                System Users List
                <Tooltip title="Filter">
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={openFilter}
                  >
                    <img src={filicon} />
                  </IconButton>
                </Tooltip>
                {isAnyFilterApplied && (
                  <Tooltip title="Clear filter">
                    <IconButton
                      aria-label="delete"
                      sx={{ padding: "10px" }}
                      size="small"
                      onClick={clearFilter}
                    >
                      <img src={clrfilter} />
                    </IconButton>
                  </Tooltip>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Paper
                sx={{
                  p: "2px 6px",
                  display: "flex",
                  alignItems: "center",
                  width: "-webkit-fill-available",
                  marginTop: "auto",
                  marginBottom: "auto",
                  height: "50px",
                  boxShadow: "none",
                  borderRadius: "5px",
                }}
              >
                <InputBase
                  sx={{ flex: 1 }}
                  placeholder="Search ..."
                  inputProps={{ "aria-label": "   search" }}
                  onChange={setSearchFun}
                  value={search}
                />
                <IconButton
                  type="submit"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  style={{
                    backgroundColor: "#FFAB00",
                    borderRadius: "10%",
                    color: "#fff",
                  }}
                  onClick={setSearchFun}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Grid>
            <Grid item xs={12} md={1}>
              <Tooltip title="Add system user">
                <IconButton
                  type="submit"
                  sx={{ p: "12px" }}
                  aria-label="search"
                  style={{
                    backgroundColor: "#00A1F1",
                    borderRadius: "10%",
                  }}
                  onClick={addUserFun}
                >
                  <img src={addicon} style={{ color: "#FFFFFF" }} />
                </IconButton>
              </Tooltip>
            </Grid>
            {loading ? (
              <Grid item xs={12} md={12} lg={12} sx={{ display: "flex" }}>
                <Grid item xs={5} md={5}></Grid>
                <Grid item xs={2} md={2}>
                  <CustomLoader loading={loading} />
                </Grid>
              </Grid>
            ) : !loading && data && data.length == 0 ? (
              <Grid item xs={12} md={12} lg={12} sx={{ display: "flex" }}>
                <Grid item xs={4} md={4}></Grid>
                <Grid item xs={2} md={2}>
                  <Box sx={{ textAlign: "center", mt: "15px" }}>
                    <Typography>No matching records found.</Typography>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}

            {!loading &&
              data &&
              data.map((r, i) => {
                const { id, firstName, lastName, email } = r;
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{
                      display: "flex",
                      backgroundColor: "white",
                      marginTop: "15px",
                      marginLeft: { xs: "0px", sm: "0px", md: "20px" },
                      width: { xs: "auto", sm: "auto", md: "975px" },
                      height: "auto",
                      padding: "24px !important",
                    }}
                    data-id={id}
                    key={id}
                  >
                    <Grid item xs={12} md={9} sx={{ display: "content" }}>
                      <Stack direction="row" spacing={2}>
                        <Typography
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                          }}
                          data-id={id}
                          onClick={handleViewUser}
                        >
                          {lastName
                            ? firstName.concat(" ").concat(lastName)
                            : firstName || ""}
                        </Typography>
                      </Stack>

                      <Typography
                        style={{
                          marginTop: "15px",
                          fontSize: "12px",
                          fontWeight: 500,
                        }}
                      >
                        {email || "N/A"}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={1}>
                      <Box
                        sx={{
                          p: "3px 5px",
                          border: "1px solid #BBB5B5",
                          borderRadius: "8%",
                          textAlign: "center",
                          width: "-webkit-fill-available",
                          maxWidth: "70px",
                          float: { xs: "left", sm: "left", md: "right" },
                          borderRadius: "5px",
                          marginTop: "10px !important",
                        }}
                      >
                        <Typography
                          className="acti-ad"
                          style={{ fontSize: "10px", fontWeight: "700" }}
                        >
                          {r.role}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <Stack
                        sx={{ pr: "10px", mt: { xs: "15px", md: "0px" } }}
                        direction="row"
                        spacing={2}
                        justifyContent={{
                          xs: "flex-start",
                          sm: "flex-start",
                          md: "flex-end",
                        }}
                      >
                        <Box
                          style={{
                            backgroundColor: "#7cbb00",
                            borderRadius: "15px",
                          }}
                          onClick={handleClickOpen}
                          data-enableid={id}
                        >
                          <FormControlLabel
                            style={{ marginLeft: "2px", color: "#fff" }}
                            control={<IOSSwitch sx={{ m: 1 }} checked={true} />}
                            label={"Enabled"}
                          />
                        </Box>
                      </Stack>
                      <StyledMenu
                        className="userl-popup"
                        id="demo-customized-menu"
                        MenuListProps={{
                          "aria-labelledby": "demo-customized-button",
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem disableRipple>
                          <img src={editIcon} />
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={handleDelete}
                          disableRipple
                          data-id={id}
                        >
                          <img src={delIcon} />
                          Delete
                        </MenuItem>
                      </StyledMenu>
                      <Dialog
                        open={openPop}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        BackdropProps={{
                          style: {
                            backgroundColor: "rgba(0, 0, 0, 0.1)", // Light, semi-transparent black
                            // backdropFilter: 'blur(1px)'           // Subtle blur effect
                          },
                        }}
                        PaperProps={{
                          elevation: 3, // Light shadow for the dialog
                        }}
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Are you sure?"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Do you want to Disable the user?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClosePop}>No</Button>
                          <Button onClick={handleClosePopyes} autoFocus>
                            Yes
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Box>
      {adduser && (
        <AddNewSystemUser
          open={adduser}
          handleClose={addUserFun}
          data={data}
          successcall={successcall}
        />
      )}
      {openfil && (
        <SystemUserFilter
          open={openfil}
          handleClose={openFilter}
          setFilter={setFilter}
          filter={filter}
          setSearch={setSearch}
        />
      )}
    </>
  );
}
