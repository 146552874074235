import Axios from "axios";
import { CustomMessage } from "./mainfunctions";
import { useSnackbar } from "notistack";
function clearCookie(name) {
  document.cookie = `${name}=; path=/; domain=.hrassist.app; expires=Thu, 01 Jan 1970 00:00:00 UTC; samesite=lax`;
}
const API = Axios.create();
API.interceptors.response.use(
  (response) => {
    let apitime = new Date(localStorage.getItem("API_TIME")) || new Date();

    let dm = sessionStorage.getItem("DOMAIN");
    var endDate = new Date();
    var seconds =
      (endDate.getTime() - (apitime?.getTime() || new Date())) / 1000;

    let rememberme = localStorage.getItem("RememberMe");

    if (60 * 60 < seconds) {
      // CustomMessage('Session expired. Please log in again.','error',enqueueSnackbar)
      if (rememberme == "true") {
        localStorage.removeItem("AUTH_TOKEN");
        localStorage.removeItem("API_TIME");
        clearCookie("userToken");

        localStorage.clear();
        window.open("https://hrassist.app/signup", "_self");
      } else {
        const cookies = document.cookie.split(";");

        cookies.forEach((cookie) => {
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie =
            name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        });
        localStorage.clear();
        // if (!window.location.href.includes("/")) {
        //   window.location.replace(`/`);
        // }
        window.open("https://hrassist.app/signup", "_self");

      }
    } else {
      localStorage.setItem("API_TIME", new Date());
    }
    return response;
  },
  (error) => {
    // console.error("error", error);
    let dm = sessionStorage.getItem("DOMAIN");

    const { status, data } = error.response;
    if (status === 401) {
      
      clearCookie("userToken");

      window.open("https://hrassist.app/signup", "_self");
      localStorage.clear();
      // if (!window.location.href.includes("/")) {
      //   window.location.replace(`/`);
      // }
    }
    return Promise.reject(error);
  }
);
export default API;
